
import React, { PureComponent } from "react";
import axios from "axios";
import * as GlobalService from '../assets/js/global_services.js';
import loginFormLogo from "../assets/images/3PL_Central_RADIATE.png";
import WmsButton from './../components/WmsButton/WmsButton'
// import WmsInput from './../components/WmsInput/WmsInput'
import "./Login.scss";

declare var window: any;
interface IProps {
    onLoginSuccess?: () => void;
}
interface IState {
    failedLogin?: string;
    username?: string;
    password?: string;
    submitting?: boolean;
}
class LoginComponent extends PureComponent<IProps, IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            failedLogin: '',
            username: '',
            password: '',
            submitting: false,
        };
    }

    componentDidMount = () => {
        if (window.location.href.indexOf('?code=') !== -1) {
            const urlSearchParams = new URLSearchParams(window.location.search);
            let redirectCode = urlSearchParams.get('code');
            const params = new URLSearchParams({
                code: redirectCode ? redirectCode.toString() : '',
                grant_type: "authorization_code",
                client_id: '3mpg7gdphdht5ga2h8jcsnsmf0',
                redirect_uri: window.location.origin
            });
            axios({
                url: 'https://m3a.auth.us-east-2.amazoncognito.com/oauth2/token',
                method: 'POST',
                data: params
            }).then((response) => {
                // console.log("auth response", response.data.access_token)
                // console.log("Auth response:", response);

                if (this.props.onLoginSuccess) {
                    this.props.onLoginSuccess();
                }
                window.localStorage.setItem("authToken", response.data.access_token);
                GlobalService.showSuccessMsg('login successfully');
            }).finally(() => {
            });
        }
    }

    handleInputChange = (event: any) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleSubmit = (e: any) => {
        this.setState({ submitting: true });
        this.updateIcons();
        axios.post('https://6qut7c4p7c.execute-api.us-east-2.amazonaws.com/v1/auth', {
            "username": this.state.username,
            "password": this.state.password
        }).then((response) => {
            console.log("Auth response:",response);
            if (this.props.onLoginSuccess) {
                this.props.onLoginSuccess();
            }
            window.localStorage.setItem("authToken", response.data.AccessToken);
            GlobalService.showSuccessMsg('login successfully');
        }).finally(() => {
            this.updateIcons();
            this.setState({ submitting: false });
        });
        
        
    };
    updateIcons = () => {
        console.log("updateIcons")
        setTimeout(() => {
          if(window.friconix_update) {
            window.friconix_update();
          }
        }, 0);
      }
    _handleKeyDown = (e: any) => {
        if (e.key === 'Enter' && this.state.username !== "" && this.state.password !== "") {
            this.handleSubmit({});
        }
    }
    handleSSOAuth = () => {
        window.location.href = 'https://m3a.auth.us-east-2.amazoncognito.com/login?client_id=3mpg7gdphdht5ga2h8jcsnsmf0&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=' + window.location.origin
    }
    render() {
        return (
            <div className="wms-bg-cover">
                <div className="wms-inner-block">
                    <div
                        className={`wmsi-error-msg-box ${this.state.failedLogin ? "show-error-msg" : ""
                            }`}
                    >
                        <div className="wmsi-error-bg-block">
                            <div className="">
                                <h4>Error: Incorrect username and password combination.</h4>
                                <p>Please double check your information and try again.</p>
                            </div>
                            <div className="wmsi-error-icon">
                                <i className="fas fa-exclamation-triangle" />
                            </div>
                        </div>
                    </div>
                    <div className="wmsi-modal-block">
                        <div className="wms-middle-box">
                            <div className="wms-middle-block">
                                <div className="wms-half">
                                    <div className="wms-blue-block">
                                        <img src={loginFormLogo} alt="" srcSet="" />
                                    </div>
                                </div>
                                <div className="wms-white-block">
                                    <h3>DEPLOY CONSOLE LOGIN</h3>
                                    {/* <div className="wms-group">
                                        <WmsInput
                                            value={this.state.username}
                                            id="username"
                                            inputName="username"
                                            label="Username"
                                            onChangeFunc={this.handleInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="wms-group">
                                        <WmsInput
                                            value={this.state.password}
                                            id="password"
                                            inputName="password"
                                            label="Password"
                                            onChangeFunc={this.handleInputChange}
                                            required
                                            inputType="password"
                                            onKeyDown={this._handleKeyDown}
                                        />
                                    </div> */}
                                    <div className="wms-submitbtn">
                                        {/* <WmsButton
                                            id="login"
                                            type="submit"
                                            label="Login"
                                            icon="fi-xwsuxl-sign-in-solid"
                                            buttonType="orange"
                                            disabled={this.state.submitting || this.state.username === "" || this.state.password === ""}
                                            loading={this.state.submitting}
                                            onClickFunc={this.handleSubmit}
                                        /> */}
                                        {/* <span className="wms-separator">OR</span> */}
                                        <WmsButton
                                            id="loginwithsso"
                                            type="submit"
                                            label="Login with SSO"
                                            icon="fi-xwsuxl-sign-in-solid"
                                            buttonType="blue"
                                            disabled={this.state.submitting}
                                            onClickFunc={this.handleSSOAuth}
                                        />
                                    </div>

                                </div>
                            </div>
                            <p className="wms-copyright">Copyright © 3PL Central 2020</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default LoginComponent;