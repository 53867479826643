import React, { PureComponent } from "react";
import axios from "axios";
import VirtualGridComponent from '../components/VirtualGridComponent/VirtualGridComponent'
import * as GlobalService from '../assets/js/global_services.js';
import WmsDropdown from '../components/WmsDropdown/WmsDropdown';
import WmsButton from '../components/WmsButton/WmsButton';
import WmsCheckBox from '../components/WmsCheckBox/WmsCheckBox';
import WmsModel from '../components/WmsModel/WmsModel';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse';
import "./FeatureBranches.scss";

declare var window: any;
declare var toastr: any;
const { $ } = window;

interface IProps { }
interface IProps { }
interface IState {
  batchOptions?: any,
  selectedBRanch?: string,
  createBranchLoader?: boolean,
  isBatchAlreadyExist?: boolean,
  gridLoading?: boolean,
  isOpenViewBranch?: boolean,
  isOpenBranchDeleteConfirmModel?: boolean,
  onBranchDeleteLoading?: boolean,
  isOpenBuildAndDeployModel?: boolean,
  onBuildLoading?: boolean,
  currentBatchItem?: any,
  viewBranchDetail?: any,
  gridOptions?: any,
  columns?: any,
  schema?: any,
  isWmsChecked?: boolean,
  selectedRepoList?: any
}
class FeatureBranchesComponent extends PureComponent<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      batchOptions: [],
      gridOptions: [],
      selectedBRanch: '',
      createBranchLoader: false,
      isBatchAlreadyExist: false,
      gridLoading: false,
      isOpenViewBranch: false,
      isOpenBranchDeleteConfirmModel: false,
      onBranchDeleteLoading: false,
      isOpenBuildAndDeployModel: false,
      onBuildLoading: false,
      currentBatchItem: {},
      viewBranchDetail: {},
      isWmsChecked: false,
      selectedRepoList: [],
      columns: [{
        field: "ID",
        title: "Id",
        hidden: true,
        width: "140px"
      }, {
        field: "Branch",
        title: "Branch",
        width: "140px"
      }, {
        field: "Status",
        title: "Status",
        width: "100px"
      }, {
        field: "Build",
        title: "Build",
        width: "140px"
      }, {
        field: "Deployed",
        title: "Deployed",
        width: "100px"
      }, {
        field: "Url",
        title: "Uri",
        width: "240px",
        template: '#if(Url) {# #=Url# <a href="#=Url#" class="download-file" target="_blank" download><i class="fi-xwluxl-external-link-wide"></i></a> #} #'
        // }, {
        //   field: "CreatedAt",
        //   title: "Create Date",
        //   width: "140px",
        //   template: "#= (typeof CreatedAt != 'undefined' && CreatedAt != null) ?  kendo.toString(kendo.parseDate(CreatedAt), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
      }, {
        field: "Ip",
        title: "Ip",
        width: "100px"
      }, {
        field: "Hostname",
        title: "Host Name",
        width: "140px"
      }, {
        field: "UpdatedAt",
        title: "Update Date",
        hidden: true,
        width: "140px",
        template: "#= (typeof UpdatedAt != 'undefined' && UpdatedAt != null) ?  kendo.toString(kendo.parseDate(UpdatedAt), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
      }, {
        field: "DeletedAt",
        title: "Delete Date",
        hidden: true,
        width: "140px",
        template: "#= (typeof DeletedAt != 'undefined' && DeletedAt != null) ?  kendo.toString(kendo.parseDate(DeletedAt), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
      }, {
        field: "action",
        title: "Action",
        width: "60px",
        template: '<div class="wms-button-component build-deploy " id="build-deploy"><div class="button_holder "><button type="submit" id="createInfra" class="  blue" ><i class="fi-xwsuxl-plus-solid"></i></button><div></div></div></div>'
      }, {
        command: [{
          name: "viewBranch",
          template: "<i class='fi-xnsuxl-eye-solid view-record' id='view-record'></i>",
          text: 'X',
          visible: function (dataItem: any) { return (dataItem.Status !== "terminated") },
          click: function (e: any) {
            // command button click handler
            console.log("click")
          }
        }, {
          name: "details",
          template: "<i class='fi-xnsuxl-trash-bin charge-delete' id='delete-recored'></i>",
          text: 'X',
          visible: function (dataItem: any) { return (dataItem.Status !== "terminated") },
          click: function (e: any) {
            // command button click handler
            console.log("click")
          }
        }],
        width: 70,
      }],
      schema: {
        model: {
          fields: {
            Branch: { type: 'string' }
          }
        }
      }
    }
  }


  componentDidMount() {

    this.updateIcons();
    window.jQuery("#processedBatchedShippingGrid").on("click", "#delete-recored", (index: any, element: any) => {
      console.log(window.jQuery(index.target).closest('tr'));
      var currentDataItem = window.jQuery("#processedBatchedShippingGrid").data("kendoGrid").dataItem(window.jQuery(index.target).closest('tr'));
      this.setState({
        isOpenBranchDeleteConfirmModel: true,
        currentBatchItem: currentDataItem
      });
      this.updateIcons();
    });
    window.jQuery("#processedBatchedShippingGrid").on("click", "#view-record", (index: any, element: any) => {
      console.log(window.jQuery(index.target).closest('tr'));
      var currentDataItem = window.jQuery("#processedBatchedShippingGrid").data("kendoGrid").dataItem(window.jQuery(index.target).closest('tr'));
      this.openViewBranch(currentDataItem.Branch);

    });
    window.jQuery("#processedBatchedShippingGrid").on("click", "#build-deploy", (index: any, element: any) => {
      console.log(window.jQuery(index.target).closest('tr'));
      var currentDataItem = window.jQuery("#processedBatchedShippingGrid").data("kendoGrid").dataItem(window.jQuery(index.target).closest('tr'));
      this.setState({
        isOpenBuildAndDeployModel: true,
        currentBatchItem: currentDataItem
      });
      this.updateIcons();
      // this.onBuildDeploy(currentDataItem.Branch);
    });

    axios.get('https://no9xglgy0i.execute-api.us-east-2.amazonaws.com/prod/getBranches').then((branchesResponse) => {

      console.log("branchesResponse", branchesResponse);

      this.setState({
        batchOptions: branchesResponse.data
      }, () => {
        this.getGridData();
      });
    });
  }

  onCloseDeleteBatchConfirmationModel = (e: any) => {
    $("#confirmDeleteBatch").data("kendoWindow").close();
    this.setState({
      isOpenBranchDeleteConfirmModel: false,
      currentBatchItem: {}
    });
  }
  onCloseBuildAndDeployModel = (event: any) => {
    this.setState({
      isOpenBuildAndDeployModel: false,
      currentBatchItem: {},
      selectedRepoList: []
    });
  }

  updateIcons = () => {
    console.log("updateIcons")
    setTimeout(() => {
      if (window.friconix_update) {
        window.friconix_update();
      }
    }, 0);
  }
  openViewBranch = (batchName: string) => {
    console.log("openViewBranch");
    if (batchName) {

      window.kendo.ui.progress(window.jQuery("#processedBatchedShippingGrid"), true);
      axios.get('https://ky2mhvg52l.execute-api.us-east-2.amazonaws.com/main/deploy/branchiments?branch=' + batchName).then(response => {

        console.log("get branch detail response : ", response.data);
        this.setState({
          isOpenViewBranch: true,
          viewBranchDetail: response.data
        })


      }).finally(() => {
        window.kendo.ui.progress(window.jQuery("#processedBatchedShippingGrid"), false);
      });
    }
  }
  closeViewBranch = () => {
    console.log("closeViewBranch");
    this.setState({
      isOpenViewBranch: false
    });
  }

  changeBranches = (e: any) => {
    var isBatchAlreadyExist = this.isBatchAlreadyExist(e.target.value);
    this.setState({
      selectedBRanch: e.target.value,
      isBatchAlreadyExist: isBatchAlreadyExist
    });
  }

  isBatchAlreadyExist = (batchName: any) => {
    let isBatchAlreadyExist = false;
    // check for only 'running' status
    // index = this.state.gridOptions.findIndex((x:any) => (x.name === e.target.value && x.status === 'running'));
    var index = this.state.gridOptions.findIndex((x: any) => x.Branch === batchName);
    console.log("isBatchAlreadyExist index", index, "  batchName: ", batchName);
    if (index !== -1) {
      isBatchAlreadyExist = true;
    }
    return isBatchAlreadyExist;
  }

  createFeatureInfrastructure = () => {
    console.log("selectedBRanch", this.state.selectedBRanch)

    this.setState({ createBranchLoader: true });
    this.updateIcons();
    axios.post('https://ky2mhvg52l.execute-api.us-east-2.amazonaws.com/main/deploy/branchiment', {
      'branch': this.state.selectedBRanch
    }).then((response) => {
      GlobalService.showSuccessMsg('Branch added successfully');
      this.getGridData();
      this.setState({ selectedBRanch: '' });
    }).finally(() => {
      this.setState({ createBranchLoader: false });
      this.updateIcons();
    });
  }
  // testAuthApi = () => {
  //   axios.get('/testAuthApi').then((response) => {
  //     console.log("testAuthApi response", response);
  //   }, (error) => {
  //   }).finally(() => {
  //   });
  // }

  getGridData = () => {

    this.setState({ gridLoading: true });
    this.updateIcons();

    axios.get('https://ky2mhvg52l.execute-api.us-east-2.amazonaws.com/main/deploy/branchiments').then(response => {
      this.setState({
        gridOptions: response.data
      });
    }).finally(() => {
      var isBatchAlreadyExist = this.isBatchAlreadyExist(this.state.selectedBRanch);
      this.setState({
        gridLoading: false,
        isBatchAlreadyExist: isBatchAlreadyExist
      });
      this.updateIcons();
    });

  }

  onRemoveBranch = (e: any) => {
    this.setState({ onBranchDeleteLoading: true });
    this.updateIcons();
    console.log("this.state.currentBatchItem", this.state.currentBatchItem);

    if (this.state.currentBatchItem.Branch) {

      window.kendo.ui.progress(window.jQuery("#processedBatchedShippingGrid"), true);
      axios.delete('https://ky2mhvg52l.execute-api.us-east-2.amazonaws.com/main/deploy/branchiment?branch=' + this.state.currentBatchItem.Branch).then(response => {

        console.log("remove branch response : ", response);

        if (response.status === 204) {
          GlobalService.showSuccessMsg('Branch remove successfully');
          this.onCloseDeleteBatchConfirmationModel(null);
          this.getGridData();
        }

      }).finally(() => {
        window.kendo.ui.progress(window.jQuery("#processedBatchedShippingGrid"), false);
        this.setState({ onBranchDeleteLoading: false });
        this.updateIcons();
      });
    }
  }
  onBuildDeploy = (e: any) => {
    this.setState({ onBuildLoading: true });
    this.updateIcons();

    console.log('selectedRepoList', this.state.selectedRepoList.join(','));

    if (this.state.currentBatchItem.Branch) {

      window.kendo.ui.progress(window.jQuery("#processedBatchedShippingGrid"), true);
      axios.put('https://ky2mhvg52l.execute-api.us-east-2.amazonaws.com/main/deploy/branchiment', {
        "branch": this.state.currentBatchItem.Branch,
        "status": "Build Requested",
        "build": this.state.selectedRepoList.join(',')
      }).then(response => {

        console.log("remove branch response : ", response);

        if (response.status === 204) {
          GlobalService.showSuccessMsg('Build and deploy successfully');
          this.onCloseBuildAndDeployModel(null);
          this.getGridData();
        }

      }).finally(() => {
        window.kendo.ui.progress(window.jQuery("#processedBatchedShippingGrid"), false);
        this.setState({ onBuildLoading: false });
        this.updateIcons();
      });
    }
  }

  showSuccessMsg = (msg: string) => {
    var html = '<i class="fa fa-check-circle" aria-hidden="true"></i>' + msg;
    toastr.success(html, '', {
      timeOut: 4000,
      positionClass: "toast-bottom-right",
      escapeHtml: false // for set html in message
    });
  }
  showErrorMsg = (msg: string) => {
    var html = '<i class="fa fa-check-circle" aria-hidden="true"></i>' + msg;
    toastr.error(html, '', {
      timeOut: 4000,
      positionClass: "toast-bottom-right",
      escapeHtml: false // for set html in message
    });
  }

  onRepoListChange = (e: any) => {
    var selectedRepoList = Object.assign([], this.state.selectedRepoList);

    if (selectedRepoList.indexOf(e.target.name) !== -1) {
      selectedRepoList.splice(selectedRepoList.indexOf(e.target.name), 1);
    } else {
      selectedRepoList.push(e.target.name);
    }
    this.setState({
      selectedRepoList: selectedRepoList
    });
  }

  render() {
    return (
      <div className="feature-section-wrapper">

        <div className="display-inline batch-option-wrapper">
          <WmsDropdown
            id="branches"
            name="branches"
            label={'Branches'}
            wmsSelector={"Branches"}
            onChangeFunc={this.changeBranches}
            value={this.state.selectedBRanch}
            options={this.state.batchOptions}
            valueField='value'
            textField='key'
            blankFirstOption={true}
            blankFirstOptionText={'select branch'}
          />

          <WmsButton
            id="createInfra"
            type="submit"
            label="Create"
            icon="fi-xwsuxl-plus-solid"
            onClickFunc={this.createFeatureInfrastructure}
            disabled={this.state.selectedBRanch === '' || this.state.isBatchAlreadyExist}
            loading={this.state.createBranchLoader}
            showToolTip={this.state.selectedBRanch === '' || this.state.isBatchAlreadyExist}
            tooltipText={this.state.selectedBRanch === '' ? 'Please select branch' : 'Branch already exist'}
          />
          {/* <WmsButton
            id="testAuthApp"
            label="Test Auth Token"
            icon="fi-xwsuxl-plus-solid"
            onClickFunc={this.testAuthApi}
            parentClassName='test-auth-token'
          /> */}
        </div>

        <VirtualGridComponent
          wmsSelector={'processedBatchedShippingGrid'}
          gridKeySelection={'ID'}
          staticGridData={this.state.gridOptions}
          gridName={'processedBatchedShippingGrid'}
          virtual={true}
          filterText={''}
          menu={[{
            "name": '',
            "wmsSelector": 'RefreshGrid',
            "value": [],
            "clickFunc": this.getGridData,
            "disabled": false,
            "icon": "fi-xwsuxl-reload",
            "color": "blue",
            'isContextMenu': false,
            'isButtonMenu': true,
            'parentClassName': 'refresh-btn-wrapper',
            'loading': this.state.gridLoading
          }, {
            "name": "Copy",
            "value": [{
              "name": "Copy Field",
              "value": [],
              "disabled": false,
              "disabledText": "Copy Field",
              "icon": "fa-bandcamp",
              "color": "green",
              'isContextMenu': true,
              'isButtonMenu': false,
              'wmsid': 'copyField'
            }, {
              "name": "Copy Row",
              "value": [],
              "disabled": false,
              "disabledText": "Copy Row",
              "icon": "fa-wrench",
              "color": "green",
              'isContextMenu': true,
              'isButtonMenu': false,
              'wmsid': 'copyRow'
            }],
            "disabled": false,
            "disabledText": "You may not add blahssss0",
            "icon": "fa-wrench",
            "color": "green",
            'isContextMenu': true,
            'isButtonMenu': false
          }]}
          allColumns={this.state.columns}
          defaultColumns={this.state.columns}
          showGridResult={true}
          showSortColumns={true}
          shawCheckbox={false}
          totalResult={this.state.gridOptions.length}
          id={'processedBatchedShippingGrid'}
          onSelection={() => { }}
          schema={this.state.schema}
          clickEventOfContextMenu={() => { }}
          exportable={true}
          eventClickColumns={() => { }}
          eventClickExportGrid={() => { }}
          resetButtonLabel={'Label_Costomer_Options'}
          resetButtonIcon={'fa-sliders'}
          metaDataFieldsName={''} />
        {this.state.isOpenViewBranch && (

          <WmsModel
            id='viewRecoredBranch'
            title={this.state.viewBranchDetail.Branch + "'s Detail"}
            width={540}
            height={415}
            customClass='wms-model-new-ui-wrapper'
            onCloseFunc={this.closeViewBranch}
            isOpen={this.state.isOpenViewBranch}
          >
            <div className="model-content-wrapper">
              <WmsCollapse
                id={'viewBranchDetail'}
                headerLabel={'Branch detail'}
                showCollapseText={false}
                showCollapseIcon={false}
              >
                <div className='detail-section'>
                  <label>Branch Name:</label>
                  <span>{this.state.viewBranchDetail.Branch}</span>
                </div>
				<div className='detail-section'>
                  <label>Host Name:</label>
                  <span>{this.state.viewBranchDetail.Hostname}</span>
                </div>
                <div className='detail-section'>
                  <label>Status:</label>
                  <span>{this.state.viewBranchDetail.Status}</span>
                </div>
                <div className='detail-section'>
                  <label>Build:</label>
                  <span>{this.state.viewBranchDetail.Build}</span>
                </div>
                <div className='detail-section'>
                  <label>Created By:</label>
                  <span>{this.state.viewBranchDetail.createdByUser}</span>
                </div>
                <div className='detail-section'>
                  <label>Updated By:</label>
                  <span>{this.state.viewBranchDetail.updatedByUser}</span>
                </div>
                <div className='detail-section'>
                  <label>Created Date:</label>
                  <span>{
                    this.state.viewBranchDetail.CreatedAt ?
                      window.kendo.toString(window.kendo.parseDate(this.state.viewBranchDetail.CreatedAt), 'MMM dd yyyy, hh:mm:ss tt') :
                      ''
                  }</span>
                </div>
                <div className='detail-section'>
                  <label>Update Date:</label>
                  <span>{
                    this.state.viewBranchDetail.UpdatedAt ?
                      window.kendo.toString(window.kendo.parseDate(this.state.viewBranchDetail.UpdatedAt), 'MMM dd yyyy, hh:mm:ss tt') :
                      ''
                  }</span>
                </div>
                <div className='detail-section'>
                  <label>Subdomain:</label>
                  <span>{this.state.viewBranchDetail.Subdomain}</span>
                </div>
                <div className='detail-section'>
                  <label>Error:</label>
                  <span>{this.state.viewBranchDetail.Error}</span>
                </div>
              </WmsCollapse>
            </div>
            {/* <div className="footer-btn-holder">
              <WmsButton
                id="exit"
                label="Exit"
                icon="fi-xwsuxl-plus-solid"
                buttonType="orange"
                onClickFunc={this.closeViewBranch}
              />
            </div> */}
          </WmsModel>

        )}
        {this.state.isOpenBranchDeleteConfirmModel && (
          <WmsModel
            id={'confirmDeleteBatch'}
            title={'Confirm branch delete'}
            width={600}
            height={200}
            class='confirm-remove-package-wrapper'
            onCloseFunc={this.onCloseDeleteBatchConfirmationModel}
            customClass='wms-model-new-ui-wrapper'
            isOpen={this.state.isOpenBranchDeleteConfirmModel}>
            <div className='model-content-wrapper'>
              <label className='confirm-remove-msg'> Are you sure you want to delete <b> {this.state.currentBatchItem.Branch} </b> branch? </label>
            </div>
            <div className="footer-btn-holder">
              <WmsButton
                label='Cancel'
                wmsSelector={'Cancel'}
                onClickFunc={this.onCloseDeleteBatchConfirmationModel}
                disabled={false}
                loading={false}
                buttonType='orange'
                icon={'fi-xnsuxl-times-solid'}
              />
              <WmsButton
                label={'Delete'}
                wmsSelector={'Delete'}
                onClickFunc={this.onRemoveBranch}
                buttonType='red'
                disabled={this.state.onBranchDeleteLoading}
                loading={this.state.onBranchDeleteLoading}
                icon={'fi-xnsuxl-trash-bin'}
              />
            </div>

          </WmsModel>
        )}
        {this.state.isOpenBuildAndDeployModel && (
          <WmsModel
            id={'buildAndDeploy'}
            title={'Build and deploy'}
            width={600}
            height={200}
            class='build-and-deploy-wrapper'
            onCloseFunc={this.onCloseBuildAndDeployModel}
            customClass='wms-model-new-ui-wrapper'
            isOpen={this.state.isOpenBuildAndDeployModel}>
            <div className='model-content-wrapper'>
              <WmsCollapse
                id={'repoList'}
                headerLabel={'Repo list'}
                showCollapseText={false}
                showCollapseIcon={false}
              >
                <WmsCheckBox
                  id={'WMS'}
                  name={'WMS'}
                  wmsSelector={'WMS'}
                  onChangeFunc={this.onRepoListChange}
                  label={'WMS'}
                  checked={this.state.selectedRepoList.indexOf('WMS') !== -1}
                />
                <WmsCheckBox
                  id={'Smartui'}
                  name={'Smartui'}
                  wmsSelector={'Smartui'}
                  onChangeFunc={this.onRepoListChange}
                  label={'Smart Ui'}
                  checked={this.state.selectedRepoList.indexOf('Smartui') !== -1}
                />
                <WmsCheckBox
                  id={'Scannerapp'}
                  name={'Scannerapp'}
                  wmsSelector={'Scannerapp'}
                  onChangeFunc={this.onRepoListChange}
                  label={'Scanner App'}
                  checked={this.state.selectedRepoList.indexOf('Scannerapp') !== -1}
                />
                <WmsCheckBox
                  id={'Mgmtconsole'}
                  name={'Mgmtconsole'}
                  wmsSelector={'Mgmtconsole'}
                  onChangeFunc={this.onRepoListChange}
                  label={'Management Console'}
                  checked={this.state.selectedRepoList.indexOf('Mgmtconsole') !== -1}
                />
              </WmsCollapse>
            </div>
            <div className="footer-btn-holder">
              <WmsButton
                label='Cancel'
                wmsSelector={'Cancel'}
                onClickFunc={this.onCloseBuildAndDeployModel}
                disabled={false}
                loading={false}
                buttonType='orange'
                icon={'fi-xnsuxl-times-solid'}
              />
              <WmsButton
                label={'Build and Deploy'}
                wmsSelector={'BuildAndDeploy'}
                onClickFunc={this.onBuildDeploy}
                buttonType='blue'
                disabled={this.state.onBuildLoading || this.state.selectedRepoList.length === 0}
                showToolTip={this.state.selectedRepoList.length === 0}
                tooltipText={"Select at least one repo for deploy"}
                loading={this.state.onBuildLoading}
                icon={'fi-xwsuxl-upload-solid'}
              />
            </div>

          </WmsModel>
        )}
      </div>
    );
  }
}


export default FeatureBranchesComponent;
