import React, { PureComponent } from "react";
import WmsScrollTo from '../components/WmsScrollTo/WmsScrollTo';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse';
import axios from "axios";
import VirtualGridComponent from '../components/VirtualGridComponent/VirtualGridComponent'
import "./Email.scss";

declare var window: any;
interface IState {
  gridOptions?: any,
  gridLoading?: boolean,
  columns?: any,
  schema?: any,
}
interface IProps {

}
class EmailComponent extends PureComponent<IProps, IState>  {

  constructor(props: IProps) {
    super(props);
    this.state = {
      gridOptions: [],
      gridLoading: false,
      columns: [{
        field: "EmailAddress",
        title: "Email Address",
      }, {
        field: "Reason",
        title: "Reason"
      }, {
        field: "LastUpdateTime",
        title: "Last Update Time",
        template: "#= (typeof LastUpdateTime != 'undefined' && LastUpdateTime != null) ?  kendo.toString(kendo.parseDate(LastUpdateTime), 'MMM dd yyyy, hh:mm:ss tt') : '' #"
      }],
      schema: {
        model: {
          fields: {
            EmailAddress: { type: 'string' },
            Reason: { type: 'string' },
          }
        }
      }
    }
  }

  componentDidMount() {
    this.getSuppressionGridData();
  }
  getSuppressionGridData = () => {
    this.setState({ gridLoading: true });
    window.kendo.ui.progress(window.jQuery("#suppressionEmailGrid"), true);
    this.updateIcons();
    axios.get('https://2sj1lahtc4.execute-api.us-east-2.amazonaws.com/email/suppression').then((deployResponse) => {
      this.setState({
        gridOptions: deployResponse.data
      });
    }).finally(() => {
      this.setState({
        gridLoading: false
      });
      this.updateIcons();
      window.kendo.ui.progress(window.jQuery("#suppressionEmailGrid"), false);
    });;

  }
  updateIcons = () => {
    setTimeout(() => {
      if (window.friconix_update) {
        window.friconix_update();
      }
    }, 0);
  }
  onSuppressionShow = () => {
    this.getSuppressionGridData();
    console.log("onSuppressionShow called");
  }
  render() {
    return (
      <div className="email-section-wrapper email-page">
        <div className="tab-listing" >
          <ul>
            <li>
              <WmsScrollTo
                label={'Suppression'}
                scrollToId='statusTabCollapse'
                scrollBoxId='ManageOrdersScrollBox'
                windowId="email-page"
                isTabSection={true}
                onTabShow={this.onSuppressionShow}
                tabCommonClassForHide={"email-tab-content-wrapper"} />
            </li>
          </ul>
        </div>
        <div id="ManageOrdersScrollBox" className="email-page tab-content">
          <div className="status-wrapper email-tab-content-wrapper" id="statusTabCollapse">
            <WmsCollapse
              id={'collapse1'}
              headerLabel="Suppression"
              showCollapseText={false}
              isExpanded={true}
              activeCollapse={'true'}
              windowId="email-page"
              onCollapseClick={() => { }}>
              <VirtualGridComponent
                wmsSelector={'suppressionEmailGrid'}
                gridKeySelection={'ID'}
                staticGridData={this.state.gridOptions}
                gridName={'suppressionEmailGrid'}
                virtual={true}
                filterText={''}
                gridHeight={'550px'}
                menu={[{
                  "name": '',
                  "wmsSelector": 'RefreshGrid',
                  "value": [],
                  "clickFunc": this.getSuppressionGridData,
                  "disabled": false,
                  "icon": "fi-xwsuxl-reload",
                  "color": "blue",
                  'isContextMenu': false,
                  'isButtonMenu': true,
                  'parentClassName': 'refresh-btn-wrapper',
                  'loading': this.state.gridLoading
                }, {
                  "name": "Copy",
                  "value": [{
                    "name": "Copy Field",
                    "value": [],
                    "disabled": false,
                    "disabledText": "Copy Field",
                    "icon": "fa-bandcamp",
                    "color": "green",
                    'isContextMenu': true,
                    'isButtonMenu': false,
                    'wmsid': 'copyField'
                  }, {
                    "name": "Copy Row",
                    "value": [],
                    "disabled": false,
                    "disabledText": "Copy Row",
                    "icon": "fa-wrench",
                    "color": "green",
                    'isContextMenu': true,
                    'isButtonMenu': false,
                    'wmsid': 'copyRow'
                  }],
                  "disabled": false,
                  "disabledText": "You may not add blahssss0",
                  "icon": "fa-wrench",
                  "color": "green",
                  'isContextMenu': true,
                  'isButtonMenu': false
                }]}
                allColumns={this.state.columns}
                defaultColumns={this.state.columns}
                showGridResult={true}
                showSortColumns={true}
                shawCheckbox={false}
                totalResult={this.state.gridOptions.length}
                id={'suppressionEmailGrid'}
                onSelection={() => { }}
                schema={this.state.schema}
                clickEventOfContextMenu={() => { }}
                exportable={true}
                eventClickColumns={() => { }}
                eventClickExportGrid={() => { }}
                resetButtonLabel={'Label_Costomer_Options'}
                resetButtonIcon={'fa-sliders'}
                metaDataFieldsName={''} />
            </WmsCollapse >
          </div>
        </div>
      </div>
    )
  }
}


export default EmailComponent;
