import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as GlobalService from '../src/assets/js/global_services.js';
import axios from "axios";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
axios.interceptors.request.use(function (config) {
  if (localStorage.getItem('authToken')) {
    config.headers = { Authorization: localStorage.getItem('authToken') }
  }
  return config;
}, function (error) {

})

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    console.log(error.response);
    if (error.response && error.response.status  && error.response.status === 401) { 
      localStorage.removeItem("authToken");
      localStorage.removeItem("loginRole");
      window.location.href = window.location.origin;
      // window.location.reload();
    }
    let errorMsg = error;
    if (error.response && error.response.data) {
      if (error.response.data.hint) {
        errorMsg = error.response.data.hint + '<br/>';
      }
      if (error.response.data.error) {
        errorMsg = errorMsg ? errorMsg + error.response.data.error : error.response.data.error;
      }
    }

    GlobalService.showErrorMsg(errorMsg);
    return Promise.reject(error);
  }
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
