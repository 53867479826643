export const getAmisComponentGridDemoColumns = (navigateFrom) => {
    if (navigateFrom === "sandbox") {
        return ({
            columns: [{
                field: "ami",
                title: "Ami",
                width: "140px"
            },{
                field: "build",
                title: "Code",
                width: "140px"
            },{
                field: "created",
                title: "Created Date",
                template: "#= (typeof created != 'undefined' && created != null) ?  kendo.toString(kendo.parseDate(created), 'M/dd/yyyy hh:mm tt') : '' #",
                width: "140px"
            }, {
                field: "role",
                title: "Role",
                width: "140px"
            }, {
                field: "action",
                title: "Action",
                width: "160px",
                template: function (dataItem) {
                    return (dataItem.Error ? '' : '<div class="wms-button-component" id="build-deploy"><div class="button_holder"><button type="submit" id="createInfra" class="blue" ><div>Deploy</div></button><div></div></div></div>')
                }
            }]
        });
    } else if (navigateFrom === "production" || navigateFrom === 'productiondual') {
        return ({
            columns: [{
                field: "role",
                title: "Role",
                width: "140px"
            }, {
                field: "ami",
                title: "Ami",
                width: "140px"
            }, {
                field: "build",
                title: "Code",
                width: "140px"
            }, {
                field: "error",
                title: "Error",
                width: "140px"
            }, {
                field: "action",
                title: "Action",
                width: "160px",
                template: function (dataItem) {
                    return (dataItem.error ? '' : '<div class="wms-button-component" id="build-deploy"><div class="button_holder"><button type="submit" id="createInfra" class="blue" ><div>Deploy</div></button><div></div></div></div>')
                }
            }]
        });
    } else {
        return ({
            columns: [{
                field: "ami",
                title: "Ami",
                width: "140px"
            }, {
                field: "build",
                title: "Build",
                width: "140px"
            }, {
                field: "created",
                title: "Created Date",
                template: "#= (typeof created != 'undefined' && created != null) ?  kendo.toString(kendo.parseDate(created), 'M/dd/yyyy hh:mm tt') : '' #",
                width: "140px"
            }, {
                field: "role",
                title: "Role",
                width: "140px"
            }, {
                field: "action",
                title: "Action",
                width: "160px",
                template: '<div class="wms-button-component " id="build-deploy"><div class="button_holder "><button type="submit" id="createInfra" class="  blue" ><div>Deploy</div></button><div></div></div></div>'
            }]
        });
    }
}

export const getAmisComponentGridDemoAllColumns = (navigateFrom) => {
    if (navigateFrom === "sandbox") {
        return ({
            columns: [{
                field: "Role",
                title: "Role",
                width: "140px"
            }, {
                field: "Ami",
                title: "Ami",
                width: "140px"
            }, {
                field: "Code",
                title: "Code",
                width: "140px"
            }, {
                field: "error",
                title: "Error",
                width: "140px"
            }, {
                field: "action",
                title: "Action",
                width: "160px",
                template: function (dataItem) {
                    return (dataItem.Error ? '' : '<div class="wms-button-component" id="build-deploy"><div class="button_holder"><button type="submit" id="createInfra" class="blue" ><div>Deploy</div></button><div></div></div></div>')
                }
            }]
        });
    } else if (navigateFrom === "production" || navigateFrom === "productiondual") {
        return ({
            columns: [{
                field: "role",
                title: "Role",
                width: "140px"
            }, {
                field: "ami",
                title: "Ami",
                width: "140px"
            }, {
                field: "build",
                title: "Code",
                width: "140px"
            }, {
                field: "error",
                title: "Error",
                width: "140px"
            }, {
                field: "action",
                title: "Action",
                width: "160px",
                template: function (dataItem) {
                    return (dataItem.error ? '' : '<div class="wms-button-component" id="build-deploy"><div class="button_holder"><button type="submit" id="createInfra" class="blue" ><div>Deploy</div></button><div></div></div></div>')
                }
            }]
        });
    } else {
        return ({
            columns: [{
                field: "ami",
                title: "Ami",
                width: "140px"
            }, {
                field: "build",
                title: "Build",
                width: "140px"
            }, {
                field: "created",
                title: "Created Date",
                template: "#= (typeof created != 'undefined' && created != null) ?  kendo.toString(kendo.parseDate(created), 'M/dd/yyyy hh:mm tt') : '' #",
                width: "140px"
            }, {
                field: "role",
                title: "Role",
                width: "140px"
            }, {
                field: "action",
                title: "Action",
                width: "160px",
                template: '<div class="wms-button-component " id="build-deploy"><div class="button_holder "><button type="submit" id="createInfra" class="  blue" ><div>Deploy</div></button><div></div></div></div>'
            }]
        });
    }
}

export const getAmisComponentGridDemoSchema = (navigateFrom) => {
    if (navigateFrom === "sandbox") {
        return ({
            model: {
                fields: {
                    Role: { type: 'string' },
                    Ami: { type: 'string' },
                    Code: { type: 'string' },
                    Error: { type: 'string' }
                }
            }
        })
    } else if (navigateFrom === "production" || navigateFrom === 'productiondual') {
        return ({
            model: {
                fields: {
                    role: { type: 'string' },
                    ami: { type: 'string' },
                    build: { type: 'string' },
                    error: { type: 'string' }
                }
            }
        })
    } else {
        return ({
            model: {
                fields: {
                    ami: { type: 'string' },
                    build: { type: 'string' },
                    created: { type: 'string' },
                    role: { type: 'string' }
                }
            }
        })
    }
}