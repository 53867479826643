
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import './WmsCheckBox.scss';
declare var window: any;
const { $ } = window;
interface IBaseProps {
    id?: string, // this should write the id to the checkbox
    label?: string,// this should be the label that shows after checkbox button
    name?: string,// this should be the name of the checkbox
    wmsSelector?: string, // this should set up a selector on the input
    onChangeFunc?: any, // this should control the onchange event and anything we need to determine before we send it up
    parentClassName?: string, //    this should parent tag class
    checked?: boolean, // this should disable checkbox
    isRounded?: boolean, // this is use for rounded and square checkbox shown
    showToolTip?: boolean, // set tooltip show or not
    isSwitchCheckbox?: boolean, // set tooltip show or not
    disabled?: boolean, // set tooltip show or not
    tooltipText?: string, // show text on hover as tooltip
    tooltipShowAfter?: string // show text on hover as tooltip
}
interface IState { }

type IProps = IBaseProps;
class WmsCheckBox extends PureComponent<IProps, IState> {
    public static propTypes = {};
    public static defaultProps = {};
    constructor(props: IProps) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        if (this.props.showToolTip === true) {
            setTimeout(() => {
                $("#" + this.props.id + "_chk span").kendoTooltip({
                    showAfter: this.props.tooltipShowAfter,
                    width: 240,
                    position: "bottom",
                    content: (e: any) => {
                        return '<p class="model-tooltip-title-p" data-wms-selector="' + this.props.wmsSelector + 'TooltipContent">' + this.props.tooltipText + '</p>';
                    },
                    animation: {
                        open: {
                            effects: "zoom",
                            duration: 150
                        }
                    }
                }).data("kendoTooltip");
            }, 100);
        }
    }

    render() {
        return (
            <div className={this.props.parentClassName + " wms-checkbox-component " + (this.props.isRounded ? 'wms-rounded-checkbox' : 'wms-rounded-checkbox')}>
                <label id={this.props.id + '_chk'} htmlFor={this.props.id}>
                    <input
                        type="checkbox"
                        id={this.props.id}
                        name={this.props.name}
                        data-wms-selector={this.props.wmsSelector}
                        onChange={this.props.onChangeFunc}
                        checked={this.props.checked}
                        className={(this.props.isSwitchCheckbox ? "switch" : "option-input")}
                        disabled={this.props.disabled}
                    /> <span>{this.props.label}</span>
                </label>
            </div>
        );
    }

}
WmsCheckBox.propTypes = {
    id: PropTypes.string, // this should write the id to the checkbox
    label: PropTypes.string.isRequired,// this should be the label that shows after checkbox button
    name: PropTypes.string.isRequired,// this should be the name of the checkbox
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the input
    onChangeFunc: PropTypes.func.isRequired, // this should control the onchange event and anything we need to determine before we send it up
    parentClassName: PropTypes.string, //    this should parent tag class
    checked: PropTypes.bool, // this should disable checkbox
    isRounded: PropTypes.bool, // this is use for rounded and square checkbox shown
    showToolTip: PropTypes.bool, // set tooltip show or not
    isSwitchCheckbox: PropTypes.bool, // set tooltip show or not
    tooltipText: PropTypes.string // show text on hover as tooltip
}

WmsCheckBox.defaultProps = {
    checked: false,
    disabled: false,
    parentClassName: "",
    isRounded: false,
    showToolTip: false,
    isSwitchCheckbox: false,
    tooltipShowAfter: 800,
    tooltipText: 'add tooltip text'
}
export default WmsCheckBox;