import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import './WmsCollapse.scss';

declare var window: any;
const { $ } = window;

interface IProps {
    id?: string, // this should write the id to the input
    windowId?: string, // this should window id
    headerLabel?: string, // this should be the label that default shows whether it is collapse or expand,
    showDragIcon?: boolean, // set true to saw drag icon on header left side by default is true
    showCollapseIcon?: boolean,
    isExpanded?: boolean,
    isDisabled?: boolean,
    isShawHeaderInfoIcon?: boolean,
    showCollapseText?: boolean,
    onCollapseClick?: any,
    activeCollapse?: string
}

interface IState {
    isExpanded?: boolean;
    windowId?: string;
}

class WmsCollapse extends PureComponent<IProps, IState> {
    public static propTypes = {};
    public static defaultProps = {};
    constructor(props: IProps) {
        super(props);
        this.state = {
            isExpanded: props.isExpanded,
            windowId: props.windowId !== "" ? "." + props.windowId + " " : ""
        }
    }
    componentDidMount() {
        if (window.friconix_update) {
            window.friconix_update();
        }
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {

        if (
            this.props.isExpanded === false &&
            this.props.isExpanded !== prevState.isExpanded &&
            prevProps.activeCollapse === prevProps.id
        ) {
            var content = $("#" + this.props.id).next();
            content.slideToggle(500, () => {
                this.setState({
                    isExpanded: this.props.isExpanded
                });

                if (window.friconix_update) {
                    window.friconix_update();
                }
            });
        }
    }
    onClickEvent(e: any) {
        var id = this.props.id;
        // var subheader = $("#" + id + " ." + style.text_update);
        // Getting the next element
        var content = $(this.state.windowId + "#" + id).next();
        content.slideToggle(500, () => {
            this.setState({
                isExpanded: !this.state.isExpanded
            });
            if(window.friconix_update) {
                window.friconix_update();
            }

        });
        this.props.onCollapseClick(id);
    }

    render() {
        return (
            <div className={"wms-collapsible-div " + (this.props.isDisabled ? "wms-collapse-disabled" : "")} id={this.props.id + '_main'}>
                <div className="wms_collapsible_header" id={this.props.id} onClick={this.onClickEvent.bind(this)}>
                    <div className='title-info'>
                        <label className="wms-title">{this.props.headerLabel}</label>
                        {this.props.isShawHeaderInfoIcon && (
                            <i className="fi-xnsuxl-chevron-solid" id={this.props.id + '_headerinfo'} aria-hidden="true"></i>
                        )}
                    </div>
                    {!this.props.showCollapseText && this.props.showCollapseIcon &&
                        <label className={'icon_label '}>{this.state.isExpanded ? <i className="fi-xnsuxl-chevron-solid" aria-hidden="true"></i> : <i className="fi-xwsdxl-chevron-wide" aria-hidden="true"></i>} </label>
                    }
                    {this.props.showCollapseText &&
                        <label className={'icon_label label-text-padding '}>{this.state.isExpanded ? '- Collapse' : '+ Expand'} </label>
                    }
                </div>
                <div className="wms-collapsible-content" style={{ display: this.state.isExpanded ? 'block' : 'none' }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

WmsCollapse.propTypes = {
    id: PropTypes.string.isRequired, // this should write the id to the input
    windowId: PropTypes.string, // this should window id
    headerLabel: PropTypes.string.isRequired, // this should be the label that default shows whether it is collapse or expand,
    showDragIcon: PropTypes.bool, // set true to saw drag icon on header left side by default is true
    showCollapseIcon: PropTypes.bool,
    isExpanded: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isShawHeaderInfoIcon: PropTypes.bool
}
WmsCollapse.defaultProps = {
    isExpanded: true,
    isDisabled: false,
    showCollapseText: true,
    showDragIcon: false,
    showCollapseIcon: true,
    isShowTooltip: false,
    tooltipText: '',
    isShawHeaderInfoIcon: false,
    headerInfoTooltipText: '',
    onKeyDown: () => void (0),
    onCollapseClick: (id: string) => void (0),
    windowId: ""
}
export default WmsCollapse;