import React, { PureComponent } from "react";
import WmsCollapse from './../components/WmsCollapse/WmsCollapse'
import "./GitLearning.scss";
interface IState {
  activeCollapse?: string;
}
class GitLearningComponent extends PureComponent<{}, IState>  {

  constructor(props: any) {
    super(props);
    this.state = {
      activeCollapse: 'gitSingingUp'
    };
  }

  onCollapseClick = (id: string) => {
    this.setState({
      activeCollapse: id
    })
  }
  render() {

    return (
      <div className="git-learning-section-wrapper">
        <WmsCollapse
          id={'gitSingingUp'}
          headerLabel="Signing up"
          showCollapseText={false}
          isExpanded={this.state.activeCollapse === 'gitSingingUp'}
          activeCollapse={this.state.activeCollapse}
          onCollapseClick={this.onCollapseClick}>
            <p>In order to use git you will need a few different tools You will get a git Kraken and a gitlab account. You should have received an invitation from gitKraken in your email.</p>
            <p> If you have not found this email then please contact the git administrator. You will need to sign up for a You will also need to have a gitlab user account. If you do not have a gitlab account, please go to gitlab.com.</p>
            <a href="https://gitlab.com/users/sign_up" target="_blank" rel="noreferrer" >https://gitlab.com/users/sign_up</a>
            <p>You will need to remember the username you select and send it to the gitlab administrator to have you added to the gitlab repos.</p>
        </WmsCollapse>

        <WmsCollapse
          id={'gitDownloadingRepo'}
          headerLabel="Downloading a repo"
          showCollapseText={false}
          isExpanded={this.state.activeCollapse === 'gitDownloadingRepo'}
          activeCollapse={this.state.activeCollapse}
          onCollapseClick={this.onCollapseClick} >
            <p>You will need to get the https url of the repo you are trying to download. You should speak to your teammates or speak to the git administrator to obtain this url.</p>
            <p>You will need to remember your gitlab username and password as they will be used as authentication to the repo.</p>
            <p>Once you have the url, if you open gitKraken, go to the File -`{'>'}` Clone Repo (Ctrl + N) Choose Clone with URL Enter a location you wish to download files to on your system for the “Where to clone to” Enter the https url of the repo in the “URL” field Moving forward the official term for downloading a repo should be “clone”.</p>
            <p>See Video </p>
            <a href="https://youtu.be/YmRP5YvhQp0" target="_blank" rel="noreferrer" > https://youtu.be/YmRP5YvhQp0</a>
        </WmsCollapse>

        <WmsCollapse
          id={'gitCommittingRepo'}
          headerLabel="Committing to a repo" 
          isExpanded={this.state.activeCollapse === 'gitCommittingRepo'}
          showCollapseText={false}
          activeCollapse={this.state.activeCollapse}
          onCollapseClick={this.onCollapseClick} >
            <p>In SVN, there is one process to update file changes called Commit.</p>
            <p>In Git, this becomes a 2 step process. Commit and Push. So what is the difference between Commit and Push in Git.</p>
            <p>In Git, Commit, commits changes to your local code version. In other words, it commits it the code repo system on your own computer. </p>
            <p>A separate process called push is used to “Push” those local changes to the remote system.</p>
            <p>Others will not be able to see your changes until they have successfully been pushed to the remote system. So why the difference, why is darn git requiring two steps instead of the easier one?</p>
            <p>Git was designed as a distributed system, a system to allow many users simultaneous access. It was also designed to have gating systems in place.</p>
            <p>Although we are not STARTING with PR reviews or gating mechanisms. Let’s look at what that might look like with a traditional 1 step process for committing to a remote repo.</p>
            <p>Developer K initiates a commit on something that she is working on.</p>
            <p>This is not merged into the repo because no one has reviewed it, nor has it passed Static Code Analysis checks. At this point it is in limbo. It is neither approved or disapproved and in the meanwhile K, makes changes to her code.</p>
            <p>Three days later her merge request is rejected. There is significant drift between where she is at today and where she was then. </p>
            <p>She wants to revert back to the state she was in when she submitted her original request so she can make one little tweak that will allow her to get that commit passed.</p>
            <p>She doesn’t want to introduce all the new work that is for a different jira ticket on that other commit but there is no system in place for her to get back to that state.</p>
            <p>With a two step process, K, could switch between local commits and branches freely, allowing more flexibility.</p>
            <p>In this case, K would, “Stash” her current work in progress, reset to the previous commit, make the change, push it to the repo for review to pass static code analysis and review. Then she would “Pop” her stash to get back to her previous working state. </p>
            <p>Long story short, Git uses a two process approach that is more conducive to a system where merges are likely to be rejected because they fail to pass quality gates whether that is a human review, static review, style review, security review etc. </p>
            <p> <b>NOTE:</b> It is a good habit to PULL before you do any commits. </p>
            <p> So, if you are ready to commit the first thing you should do is </p>
            <p><b>1.</b> Do a Git Pull </p>
            <p><b>2.</b> Stage the files you want to commit </p>
            <p><b>3.</b> Commit the files to your local repository </p>
            <p><b>4.</b> Push the files to the remote repository See video: </p>
            <a href="https://youtu.be/q56xMqSAPwQ" target="_blank" rel="noreferrer" >https://youtu.be/q56xMqSAPwQ</a>
        </WmsCollapse>

        <WmsCollapse
          id={'gitCreatingBranch'}
          headerLabel="Creating a branch" 
          isExpanded={this.state.activeCollapse === 'gitCreatingBranch'}
          showCollapseText={false}
          activeCollapse={this.state.activeCollapse}
          onCollapseClick={this.onCollapseClick} >
            <p>It will often be the case that you will need to create branches. These branches will likely be created off of Master (remember, in Git, because of quality gates, Master is release ready branch). </p>
            <p>You should plan to have a branch be local AND remote. You want to store it as remote in case your laptop dies and the local version is gone. </p>
            <p>In order to satisfy having a feature branch that is local and remote the following procedure is recommended.</p>
            <p><b>1.</b> Right click on a local branch and select “Create branch here” (Creates locally)</p>
            <p><b>2.</b> Enter the branch name.</p>
            <p><b>3.</b> Right click on the local branch and select push (Pushes this branch to the remote version)</p>
            <p> See video: </p>
            <a href="https://youtu.be/cbKFMyyfrbk" target="_blank" rel="noreferrer" >https://youtu.be/cbKFMyyfrbk</a>
        </WmsCollapse>

        <WmsCollapse
          id={'gitSwitchingBranches'}
          headerLabel="Switching branches"
          isExpanded={this.state.activeCollapse === 'gitSwitchingBranches'}
          showCollapseText={false}
          activeCollapse={this.state.activeCollapse}
          onCollapseClick={this.onCollapseClick}  >
            <p>You will have times where you will be wanting to switch back and forth between branches. In git you should be switching back and forth between branches that are local.</p>
            <p>Double click and local repository to switch to that branch. The term going forward should be “checkout”. In other words, to checkout (switch to a branch), just double click the local branch name.</p>
            <p>Ok, so let’s checkout a new branch .... Ruh Roh!!! There are a whole bunch of conflicts. The files that I have as WIP (work in progress) are conflicting with the files that I am getting as part of the checkout.</p>
            <p> There may be times where I want to handle conflicts at this point, but MOST of the time, I wanted to switch branches real quick to check on something make a change, commit then get back to what I am doing.</p>
            <p>In other words, I want to switch branches without conflicts I need to resolve. In this scenario, you should ask yourself the question ... Am I ready to commit? If you are ready to commit, it is a good practice to commit before you switch branches.</p>
            <p> That way you can eliminate your WIP and always easily get back to that state later as there is a commit. If you are in a place to commit, then you should commit (and push) if appropriate before you switch branches. </p>
            <p>But I am not ready to commit. I have a bunch of garbled things that are not ready to go in, what do I do, oh no!!!! You can stash your uncommitted changes.</p>
            <p><b>1.</b> Click on your WIP and then click Stash along the top</p>
            <p><b>2.</b> Checkout the new branch</p>
            <p><b>3.</b> Checkout your old branch</p>
            <p><b>4.</b> On the bottom left icon where it says stashes, use the hamburger icon to apply stash</p>
            <p>So what is the difference between Apply stash and Pop Stash. Rule of thumb USE APPLY, not POP, until you are comfortable with it. Apply will move your changes back into your WIP and keep the stash in existence.</p>
            <p>You can then delete the stash manually. Pop will run Apply and Delete Stash at the same time. You will probably get used to using Pop, but for the time being you may want to just Apply the stash so you don’t lose anything in case you make a mistake.</p>
            <p>See Video </p>
            <a href="https://youtu.be/TKlM_KxR-Rw" target="_blank" rel="noreferrer" >https://youtu.be/TKlM_KxR-Rw</a>
        </WmsCollapse>

        <WmsCollapse
          id={'gitMergeBranch'}
          headerLabel="Merge a Branch"
          isExpanded={this.state.activeCollapse === 'gitMergeBranch'}
          showCollapseText={false}
          activeCollapse={this.state.activeCollapse}
          onCollapseClick={this.onCollapseClick}  >
            <p>To start off, you should get in the habit of NOT CHERRY PICKING. You should also get in the habit of not Rebasing.</p>
            <p> The current recommended approach is merging. If you are about to cherry pick something, you should ask yourself, should I be cherry picking or doing something else? Don’t know what cherry pick is, don’t worry about it then :).</p>
            <p> Don’t know what rebasing is, don’t worry about it then :) Since the main methodology for moving code between branches is merging, you should always plan to use merging as your first tool in moving code. So how do I merge branches? You will want to merge branches locally first, then push it to remote.</p>
            <p><b>1.</b> Make sure the branch you are wanting to merge on your local is up to date (by doing a pull) and then make sure the branch you are merging too is up to date on your local (by doing a pull). This can help remove the possibility of conflicts.</p>
            <p><b>2.</b>  First attempt to merge master to your feature branch and resolve conflicts there</p>
            <p><b>3.</b>  Attempt to merge your feature branch to master See Video</p>
            <a href="https://youtu.be/ytr07PMVQKA" target="_blank" rel="noreferrer" >https://youtu.be/ytr07PMVQKA</a>
        </WmsCollapse>

        <WmsCollapse
          id={'gitCherryPick'}
          headerLabel="Cherry Pick"
          isExpanded={this.state.activeCollapse === 'gitCherryPick'}
          showCollapseText={false} 
          activeCollapse={this.state.activeCollapse}
          onCollapseClick={this.onCollapseClick} >
            <p> Again, Cherry Pick should be a scenario you do not encounter alot of. Cherry picking would occur if you were not using feature branches and needed to just grab 1 commit from some other branch.</p>
            <p> Since you should be merging whole branches, not grabbing individual commits from branches this is something you SHOULD rarely do. We will go over how it can be done however if it needed to. See video </p>
            <a href="https://youtu.be/ieuzkl8PXH8" target="_blank" rel="noreferrer" >https://youtu.be/ieuzkl8PXH8</a>
        </WmsCollapse>

        <WmsCollapse
          id={'gitResolvingConflicts'}
          headerLabel="Resolving conflicts" 
          isExpanded={this.state.activeCollapse === 'gitResolvingConflicts'}
          showCollapseText={false}
          activeCollapse={this.state.activeCollapse}
          onCollapseClick={this.onCollapseClick} >
            <p>Resolving conflicts can be hard. They can be done either directly on the file itself, or using the Git Kraken tool.</p>
            <p> Remember, to merge master into your feature branch first to attempt to resolve conflicts on your feature branch, rather than trying to resolve conflicts on the merge to master.Todo see video</p>
            <a href="https://youtu.be/Ye0CeBsn3Xc" target="_blank" rel="noreferrer" >https://youtu.be/Ye0CeBsn3Xc</a>
        </WmsCollapse>

        <WmsCollapse
          id={'gitHardReset'}
          headerLabel="Hard Reset"
          isExpanded={this.state.activeCollapse === 'gitHardReset'}
          showCollapseText={false}
          activeCollapse={this.state.activeCollapse}
          onCollapseClick={this.onCollapseClick}  >
            <p>In Git you can use hard, mixed or soft resets. Depending on your workflows, you will probably use hard reset the most. This resets your code and WIP to the exact commit.</p>
            <p>This is useful when you have code that you don’t really want in WIP and want to reset to a previous state. It can also be useful to get to a specific version of a checkin for debugging. See Movie</p>
            <a href="https://youtu.be/H9xNCr9AVeE" target="_blank" rel="noreferrer" >https://youtu.be/H9xNCr9AVeE</a>
        </WmsCollapse>

        <WmsCollapse
          id={'gitcurrentProcedure'}
          headerLabel="What if I disagree with a current procedure"         
          isExpanded={this.state.activeCollapse === 'gitcurrentProcedure'} 
          showCollapseText={false}
          activeCollapse={this.state.activeCollapse}
          onCollapseClick={this.onCollapseClick}  >
            <p>In git, as well as in all software, there are many different approaches. Merge vs Rebase is a pretty hot topic, for example. If you disagree with a current practice and would like to introduce a change you should speak to your direct supervisor who will bring it up within a group setting to discuss.</p>
            <p>Please provide the following:</p>
            <p><b>1.</b> The point points </p>
            <p><b>2:</b> The alternative you are suggesting</p>
            <p><b>3.</b> The reasoning you believe the alternative will result in less pain</p>
            <p>We will obviously be watching over and expanding the recommendations. To start, we have errored on simplicity. For example, we are not introducing PR/MR initially as we think that might complicate peoples migrating to git initially.</p>
            <p>Also, Merge is a much safer and easier option than Rebase so it has been selected as the default with the current internal proficiency. As we expand our internal knowledge on git, we can expand our approaches as well.</p>
        </WmsCollapse>
      </div>
    );
  }
}


export default GitLearningComponent;
