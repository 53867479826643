import React, { PureComponent } from "react";
import FeatureBranchesComponent from "../../FeatureBranches/FeatureBranchesComponent";
import DashboardDefaultComponent from "../../Dashboard/DashBoardDefaultComponent";
import AmisComponent from "../../Amis/AmisComponent";
import EmailComponent from "../../Email/EmailComponent";
import GitLearningComponent from "../../GitLearning/GitLearningComponent";

import "./Content.scss";
declare var window: any;
const { $ } = window;

interface IProps {
  activeMenu?: string;
}

class ContentComponent extends PureComponent<IProps, {}> {


  componentDidMount() {
    $("#dropdownKendoMenu").kendoMenu({});
  }


  logout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("loginRole");
    // window.location.reload();
    window.location.href = window.location.origin
  };

  render() {
    return (
      <div className="wms-main-content">
        <header className="wms-header-navigation-menu">
          <div className="wms-user-settings">
            <div className="wmsi-user-login">
              <ul id="dropdownKendoMenu">
                <li>
                  <button className="user-name">Sys Admin</button>
                  <ul>
                    <li>
                      <span onClick={this.logout}>Sign out</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </header>

        <div className={`wms-container`}>
          {
            this.props.activeMenu === 'dashboard' ?
              <DashboardDefaultComponent /> :
              null
          }
          {
            this.props.activeMenu === 'feature-branches' ?
              <FeatureBranchesComponent /> :
              null
          }

          {
            this.props.activeMenu === 'staging' ?
              <AmisComponent
                navigateFrom='staging' /> :
              null
          }
          {
            this.props.activeMenu === 'stagingv2' ?
              <AmisComponent
                navigateFrom='stagingv2' /> :
              null
          }
          {
            this.props.activeMenu === 'stagingv2dual' ?
              <AmisComponent
                navigateFrom='stagingv2dual' /> :
              null
          }
          {
            this.props.activeMenu === 'sandbox' ?
              <AmisComponent
                navigateFrom='sandbox' /> :
              null
          }
          {
            this.props.activeMenu === 'prod' ?
              <AmisComponent
                navigateFrom='production' /> :
              null
          }
          {
            this.props.activeMenu === 'proddual' ?
              <AmisComponent
                navigateFrom='productiondual' /> :
              null
          }
          {
            this.props.activeMenu === 'email' ?
              <EmailComponent /> :
              null
          }
          {
            this.props.activeMenu === 'git-learning' ?
              <GitLearningComponent /> :
              null
          }
        </div>

      </div>
    );
  }
}

export default ContentComponent;
