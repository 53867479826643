export class KeyedCollectionWms {
  items: any;
  count: number;
  constructor() {
    this.items = {};
    this.count = 0;
  }
  /**
   * Simple contains method for Dictionary
   * @params key:string
   * @return boolean
   */
  public ContainsKey = (key: any) => {
    return this.items.hasOwnProperty(key);
  };

  /**
   * Returns numeric count of dictionary
   * @params key:string, value: T, optional mybool:boolean
   * @return void
   */
  public Add = (key: any, value: any, param?: string) => {
    //console.log("Add method");
    if (!this.ContainsKey(key)) {
      //	console.log("adding key");
      this.items[key] = value;
      this.count++;
    } else {
      if (param) {
        if (param === "remove") {
          console.log("removing key");
          this.Remove(key);
        } else if (param === "override") {
          console.log("override reached");
          this.Remove(key);
          this.items[key] = value;
          this.count++;
        }
      }
    }
  };

  /**
   * Get Item
   * @params key:string
   * @return Item
   */
  public Item = (key: any) => {
    return this.items[key];
  };

  /**
   * Return key values
   * @params
   * @return string[]
   */
  public Keys = () => {
    var keySet = [];
    for (var prop in this.items) {
      if (this.items.hasOwnProperty(prop)) {
        keySet.push(prop);
      }
    }
    return keySet;
  };
  /**
   * REturn values
   * @params
   * @return T[]
   */
  public Values = () => {
    var values = [];
    for (var prop in this.items) {
      if (this.items.hasOwnProperty(prop)) {
        values.push(this.items[prop]);
      }
    }
    return values;
  };
  /**
   * Returns numeric count of dictionary
   * @params none
   * @return number
   */
  public Count = () => {
    return this.count;
  };

  /**
   * Empty Dictionary
   * @params
   * @return void
   */
  public Clear = () => {
    for (var prop in this.items) {
      if (this.items.hasOwnProperty(prop)) {
        this.Remove(prop);
        this.count = 0;
      }
    }
  };

  /**
   * Remove element from dictionary
   * @params key:string
   * @return val
   */
  public Remove = (key: any) => {
    if (this.ContainsKey(key)) {
      var val = this.items[key];
      delete this.items[key];
      this.count--;
      return val;
    }
  };
}
