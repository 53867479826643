import React, { PureComponent } from "react";
import navigation_logo from "../../assets/images/logo.png";
import "./Navigation.scss";

declare var window: any;
const { $ } = window;

interface IProps {
  activeMenu?: any;
  activeTab?: any;
  onNavBarClick?: (clickedNavItem: string) => void;
}

class NavigationComponent extends PureComponent<IProps, {}> {

  componentDidMount() {
    this.initKendoMenu();
  }

  initKendoMenu = () => {
    let preventOpenMenu = true;
    $(".wms-menu-list").kendoMenu({
      orientation: "vertical",
      closeOnClick: true,
      animation: false,
      openOnClick: {
        subMenuItems: true,
        rootMenuItems: true
      },
      open: (event: any) => {
        if (preventOpenMenu) {
          event.preventDefault();
        }
      },
      select: (event: any) => {
        setTimeout(() => {
          if (preventOpenMenu) {
            const menuItem = $(event.item);
            let activeMenu = menuItem.data("id");

            if (this.props.onNavBarClick) {
              this.props.onNavBarClick(activeMenu);
            }

          }
        }, 0);
      }
    });

    $(document).on("click", ".wms-menu-list>li", (e: any) => {
      let isExpandArow = $(e.target).hasClass("k-menu-expand-arrow");
      preventOpenMenu = !isExpandArow;
    });
  };

  render() {
    return (
      <div className="wms-navigation-bar">
        <div className="wms-logo-container">
          <img height="50" width="57" src={navigation_logo} alt="" />
        </div>

        <div className="wms-menu-list-container">
          <ul className="wms-menu-list">
            <li
              data-id="dashboard"
              className={`wmsi-sidebar-menu-list k-item k-state-default k-first ${this.props.activeMenu === "dashboard" ? "active-tab" : ""
                }`}
            >
              <i className="fi-xnsuxl-house-solid"></i>
              <span className="wmsi-menu-list active-tab">DashBoard</span>
            </li>
            <li
              data-id="feature-branches"
              className={`wmsi-sidebar-menu-list k-item k-state-default k-first ${this.props.activeMenu === "feature-branches" ? "active-tab" : ""
                }`}
            >
              <i className="fi-xnluxl-file"></i>
              <span className="wmsi-menu-list active-tab">Feature Branches</span>
            </li>
            <li
              data-id="staging"
              className={`wmsi-sidebar-menu-list k-item k-state-default k-first ${this.props.activeMenu === "staging" ? "active-tab" : ""
                }`}
            >
              <i className="fi-xnsuxl-star-solid"></i>
              <span className="wmsi-menu-list active-tab">Slow Staging</span>
            </li>
            <li
              data-id="stagingv2"
              className={`wmsi-sidebar-menu-list k-item k-state-default k-first ${this.props.activeMenu === "stagingv2" ? "active-tab" : ""
                }`}
            >
              <i className="fi-xnsuxl-star-solid"></i>
              <span className="wmsi-menu-list active-tab">Fast Staging (V2)</span>
            </li>
            <li
              data-id="stagingv2dual"
              className={`wmsi-sidebar-menu-list k-item k-state-default k-first ${this.props.activeMenu === "stagingv2dual" ? "active-tab" : ""
                }`}
            >
              <i className="fi-xnsuxl-star-solid"></i>
              <span className="wmsi-menu-list active-tab">Fast Staging (V2Dual)</span>
            </li>
            <li
              data-id="sandbox"
              className={`wmsi-sidebar-menu-list k-item k-state-default k-first ${this.props.activeMenu === "sandbox" ? "active-tab" : ""
                }`}
            >
              <i className="fi-xnsuxl-sandbox"></i>
              <span className="wmsi-menu-list active-tab">Sandbox</span>
            </li>
            <li
              data-id="prod"
              className={`wmsi-sidebar-menu-list k-item k-state-default k-first ${this.props.activeMenu === "prod" ? "active-tab" : ""
                }`}
            >
              <i className="fi-xnsuxl-globe-thin"></i>
              <span className="wmsi-menu-list active-tab">Prod</span>
            </li>
            <li
              data-id="proddual"
              className={`wmsi-sidebar-menu-list k-item k-state-default k-first ${this.props.activeMenu === "proddual" ? "active-tab" : ""
                }`}
            >
              <i className="fi-xnsuxl-globe-thin"></i>
              <span className="wmsi-menu-list active-tab">ProdDual</span>
            </li>
            <li
              data-id="email"
              className={`wmsi-sidebar-menu-list k-item k-state-default k-first ${this.props.activeMenu === "email" ? "active-tab" : ""
                }`}
            >
              <i className="fi-xnsuxl-dot-mail"></i>
              <span className="wmsi-menu-list active-tab">Email</span>
            </li>
            <li
              data-id="git-learning"
              className={`wmsi-sidebar-menu-list k-item k-state-default k-first ${this.props.activeMenu === "git-learning" ? "active-tab" : ""
                }`}
            >
              <i className="fi-xnluxl-ordered-list"></i>
              <span className="wmsi-menu-list active-tab">Git Learning</span>
            </li>

          </ul>
        </div>
      </div>
    );
  }
}

export default NavigationComponent;
