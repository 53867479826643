

import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import "./WmsModel.scss";

declare var window: any;
const { $ } = window;

interface IBaseProps {
  isOpen?: boolean, // open and close modal
  id?: string, // this should write the id to the button
  class?: string, // this should write the id to the button
  title?: string, // title of the modal
  width?: any,// width of the modal
  height?: number,// height of the modal
  onCloseFunc?: any, // function to specify when close modal
  overlayHide?: boolean, // Specify true if overlay hide
  areyousure?: boolean, // Specify true if areyousure modal and class based on condition
  customClass?: string, // Specify classes if you want to add
  actions?: any, // Specify actions if you want to add
  confirmationModal?: boolean, // Specify true if confirmation modal and class based on condition
  warningModal?: boolean, // Specify true if warning modal and class based on condition
  isShowTooltipOnTitle?: boolean, // Specify true if you want to show tooltip on title
  currentWindow?: any
}
interface IState {
  jQueryModel?: any,
  isModalOpenFirstTime: boolean
}

type IProps = IBaseProps;
class WmsModel extends PureComponent<IProps, IState>{
  public static propTypes = {};
  public static defaultProps = {};
  constructor(props: IProps) {
    super(props);

    this.state = {
      jQueryModel: null,
      isModalOpenFirstTime: true
    };
  }

  componentDidMount() {
    // console.log("WmsModel componentDidMount called : " + this.props.id);
    this.getJqueryModel();
    // NOTE: Code For unmount model
    if (this.props.isOpen === true) {
      this.initModelAndOpen();
    }

    window.addEventListener("resize", (e: any) => {
      var modelObj = $("#" + this.props.id).data("kendoWindow");
      if (modelObj) {

        modelObj.setOptions({
          position: {
            top: 100,
            left: this.leftCalculateBasedOnModelWidth()
          },
          maxHeight: this.heightResizeAsWindowScreen()
        });
        modelObj.refresh();

      }
    });
  }


  // // NOTE: Code For unmount model
  componentWillUnmount() {
    if (typeof $("#" + this.props.id).data("kendoWindow") != "undefined") {
      $("#" + this.props.id).data("kendoWindow").close();
      $("#" + this.props.id).data("kendoWindow").destroy();
    }
  }

  getJqueryModel = () => {
    if (typeof $("#" + this.props.id).data("kendoWindow") === "undefined") {
      var maxHeight = this.heightResizeAsWindowScreen();
      $("#" + this.props.id).kendoWindow({
        title: {
          text: this.props.title,
          encoded: false
        },
        visible: false,
        cache: false,
        modal: true,
        width: this.props.width.toString() + "px",
        draggable: true,
        position: {
          top: 100,
          left: this.leftCalculateBasedOnModelWidth()
        },
        appendTo: "#root",
        resizable: false,
        // actions: this.props.actions,
        minHeight: '325px', // Minimum content area height is 150px. 150 + 80 header + 95 footer = 325
        maxHeight: maxHeight + "px"
        // refresh: function(){this.center();},
      });
    }
  }
  heightResizeAsWindowScreen = () => {
    // var properModalHeight = window.innerHeight - 100; // we need to remove header footer height and extra padding for proper modal showing
    // if(this.props.height > properModalHeight){
    //   return properModalHeight;
    // }else{
    //   return this.props.height;
    // }
    // calculation for maximum height of modal
    return window.innerHeight - 200; // 100px top padding + 100px bottom padding
  }

  leftCalculateBasedOnModelWidth = () => {
    return (window.innerWidth - this.props.width) / 2;
  }

  initModelAndOpen = () => {
    this.getJqueryModel();
    setTimeout(() => {
      var GetWindow = $("#" + this.props.id).data("kendoWindow");
      var maxHeight = this.heightResizeAsWindowScreen();
      GetWindow.setOptions({
        title: {
          text: this.props.title,
          encoded: false
        },
        width: this.props.width.toString() + "px",
        draggable: true,
        resizable: false,
        // actions: this.props.actions,
        minHeight: '325px',
        maxHeight: maxHeight + "px",
        position: {
          top: 100,
          left: this.leftCalculateBasedOnModelWidth()
        },
        open: (e: any) => {
          var winObject = $("#" + this.props.id).data("kendoWindow");
          if (this.props.overlayHide) {
            winObject.wrapper.addClass("Modal1CustomWindow border-modal");
            setTimeout(() => {
              winObject.wrapper.prev().css('opacity', '0');
            }, 100);
          } else if (this.props.areyousure) {
            winObject.wrapper.addClass("k-window-titleless areYouSureZindex");
          } else if (this.props.customClass) {
            winObject.wrapper.addClass("Modal1CustomWindow " + this.props.customClass);
          } else if (this.props.confirmationModal) {
            winObject.wrapper.addClass("areYouSureZindex");
          } else if (this.props.warningModal) {
            winObject.wrapper.addClass("areYouSureZindex");
          } else {
            winObject.wrapper.addClass("Modal1CustomWindow");
          }
          if (this.state.isModalOpenFirstTime === true && this.props.customClass === 'wms-model-new-ui-wrapper') {
            let height = winObject.wrapper.height() + 80;
            winObject.wrapper.css('height', height);
            this.setState({ isModalOpenFirstTime: false })
          }
        },
        close: (e: any) => {
        },
        deactivate: () => {
          // the closing animation is about to finish
          this.props.onCloseFunc();
        }
        // refresh: function(){this.center();}
      });
      GetWindow.open();
      // GetWindow.refresh();
    }, 0);


    if (this.props.isShowTooltipOnTitle) {
      setTimeout(() => {
        $(".tooltipfortitle").kendoTooltip({
          // width: 300,
          position: "bottom",
          content: function (e: any) {
            var target = e.target; // the element for which the tooltip is shown
            return '<p class="model-tooltip-title-p">' + target.data("tooltip-text") + '</p>';
          },
          animation: {
            open: {
              effects: "zoom",
              duration: 150
            }
          }
        }).data("kendoTooltip");

      }, 0);

    }
  }

  closeModelIfFound = () => {
    if (typeof $("#" + this.props.id).data("kendoWindow") != "undefined") {
      $("#" + this.props.id).data("kendoWindow").close();
    }
  }

  render() {
    return (
      <div>
        <div id={this.props.id} className={"Modal1 " + this.props.class} >
            {this.props.children}
        </div>
      </div>
    );
  }
}

WmsModel.propTypes = {
  isOpen: PropTypes.bool.isRequired, // open and close modal
  id: PropTypes.string.isRequired, // this should write the id to the button
  title: PropTypes.string.isRequired, // title of the modal
  width: PropTypes.number,// width of the modal
  height: PropTypes.number.isRequired,// height of the modal
  onCloseFunc: PropTypes.func.isRequired, // function to specify when close modal
  overlayHide: PropTypes.bool, // Specify true if overlay hide
  areyousure: PropTypes.bool, // Specify true if areyousure modal and class based on condition
  customClass: PropTypes.string, // Specify classes if you want to add
  actions: PropTypes.array, // Specify actions if you want to add
  confirmationModal: PropTypes.bool, // Specify true if confirmation modal and class based on condition
  warningModal: PropTypes.bool, // Specify true if warning modal and class based on condition
  isShowTooltipOnTitle: PropTypes.bool, // Specify true if you want to show tooltip on title
  currentWindow: PropTypes.object,
};

WmsModel.defaultProps = {
  overlayHide: false,
  areyousure: false,
  customClass: '',
  confirmationModal: false,
  warningModal: false,
  isAnotherModelOpen: false,
  isShowTooltipOnTitle: false,
  class: '',
  windowId: '',
  actions: ["Maximize"]
};

export default WmsModel;
