import axios from "axios";

const { $ } = window;
const { kendo } = window;
const toastr = window.toastr;

export var Rights = {};

export const constants = {
  virtualizedGridLimit: 2500,
  gridExportLimit: 5000,
  virtualGridDataLimit: 4000
};

const allStatus = {
  jobs_management: [
    {
      status: 2,
      title: "Enqueued",
      icon: "fas fa-list",
      id: "enqueued",
      count: 0,
      countClass: "wmsi-yellow"
    },
    {
      status: 1,
      title: "Scheduled",
      icon: "fas fa-calendar-alt",
      id: "scheduled",
      count: 0,
      countClass: "wmsi-yellow"
    },
    {
      status: 3,
      title: "Processing",
      icon: "fas fa-spinner",
      id: "processing",
      count: 0,
      countClass: "wmsi-yellow"
    },
    {
      status: 4,
      title: "Succeeded",
      icon: "fas fa-check-circle",
      id: "succeeded",
      count: 0,
      countClass: "wmsi-green"
    },
    {
      status: 5,
      title: "Failed",
      icon: "fas fa-exclamation-triangle",
      id: "failed",
      count: 0,
      countClass: "wmsi-red"
    },
    {
      status: 6,
      title: "Deleted",
      icon: "far fa-trash-alt",
      id: "deleted",
      count: 0,
      countClass: "wmsi-yellow"
    }
  ]
};

export const getAllStatus = (type = null) => {
  if (type) {
    return allStatus[type];
  }
  return allStatus;
};

export const getJobsStatusCount = function () {
  return axios
    .get(`${window.environments.APP_HOST}jobs/stats`)
    .then(response => {
      return allStatus.jobs_management.map(obj => {
        obj.count = response.data[obj.id];
        return obj;
      });
    })
    .catch(function (error) {
      console.log("error : ", error);
    });
};

export function sortDataset(sortArrayGrid, sorrCurrColumn) {
  for (let i = 0; i < sortArrayGrid.length; i++) {
    if (sorrCurrColumn.field === sortArrayGrid[i].field) {
      sortArrayGrid.splice(i, 1);
      break;
    }
  }
  if (sorrCurrColumn.dir) {
    sortArrayGrid.push(sorrCurrColumn);
  }
}

export const getSortedColumnName = function (sortArrayGrid, columnList) {
  const sortedColums = [];
  for (let i = 0; i < sortArrayGrid.length; i++) {
    const title = getTitleFromFields(sortArrayGrid[i].field, columnList);
    sortedColums.push(title);
  }
  return sortedColums.join(", ");
};

export function getTitleFromFields(fieldName, columnList) {
  const filterObj = columnList.filter(function (e) {
    return e.field === fieldName;
  });
  if (filterObj.length) {
    return filterObj[0].title;
  }
  return fieldName;
}

export const exportGridtoCSV = function (data, grid_selector, csvExportFileName = '') {
  let csv = "";
  const enableColumns = [];
  const grid = $(grid_selector).data("kendoGrid");
  const gridId = grid._cellId.replace("_active_cell", "");

  console.log("exportGridtoCSV grid", grid);
  const columnList = grid.columns;

  columnList.filter(function (item) {
    if (item.hidden !== true) {
      // enableColumns.push(item.field);
      enableColumns.push({ field: item.field, title: item.title });
    }
    return true;
  });
  // add the header row
  if (data.length) {
    for (var eCol in enableColumns) {
      csv += `"${enableColumns[eCol]['title']}"`;
      if (eCol !== data[0].length - 1) {
        csv += ",";
      }
    }
    csv += "\n";
  }
  // add each row of data
  // console.log(enableColumns);
  for (const row in data) {
    for (var col in enableColumns) {
      // console.log(data[row][enableColumns[col]])
      let value = data[row][enableColumns[col]['field']];
      // console.log(data[row], enableColumns[col]['field'])
      // return;
      // console.log(value)
      if (!value) {
        value = "";
      } else if (value instanceof Date) {
        value = kendo.toString(
          kendo.parseDate(value),
          "yyyy-MM-dd HH:mm:ss.sss"
        );
      } else {
        value = value.toString();
      }
      csv += `"${value}"`;
      if (col !== enableColumns.length - 1) {
        csv += ",";
      }
    }
    csv += "\n";
  }
  // TODO replace with downloadify so we can get proper file naming
  const blob = new Blob([csv], {
    type: "text/csv;charset=utf8;"
  });

  let fileName = csvExportFileName ? csvExportFileName : `${gridId}-${kendo.toString(kendo.parseDate(new Date()), "yyyyMMddhhmmss")}.csv`;
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    const a = $("<a>")
      .attr("href", window.URL.createObjectURL(blob))
      .attr("download", fileName)
      .appendTo("body");
    a[0].click();
    a.remove();
  }
};

export const returnCommaDelim = (field, str) => {
  let f = "";

  str = str.replace(/[^0-9,]/g, "");
  str = str.replace(/,\s*$/, "");

  if (str) {
    f = `${field}=in=(${str})`;
  }
  // const myarr = str.split(",");
  // for (let x = 0; x < myarr.length; x++) {
  //   x !== myarr.length - 1
  //     ? (f += `${field}==${myarr[x]},`)
  //     : (f += `${field}==${myarr[x]}`);
  // }
  return f;
};

export const apiFailer = error => {
  let res = error.response;
  let errTitle = error.message;
  let errContent = res.statusText;

  if (!res || res.status === 304) {
    // if we get not modified we don't want to throw any error here
    return;
  } else if (res.status === 400) {
    errContent = res.statusText;
  } else if (res.status === 401) {
    let isLoginUrl = error.request.responseURL.indexOf("login?") !== -1;

    if (error.response && error.response.status === 401 && !isLoginUrl) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("loginRole");
      window.location.href = window.location.origin
      // window.location.reload();
    }

    return;
  } else if (res.status === 500) {
    errContent = res.data.message;
  }

  console.log("errContent", errContent);

  let errorHandler = $("#errorHandler").data("kendoDialog");

  if (!errorHandler) {
    $("#errorHandler").kendoDialog({
      width: "450px",
      closable: false,
      modal: true,
      visible: false,
      actions: [
        {
          text: "Close",
          action: e => {
            // return false;
          }
        }
      ],
      close: e => { }
    });

    errorHandler = $("#errorHandler").data("kendoDialog");
  }

  errorHandler.title(errTitle);
  errorHandler.content(errContent);
  errorHandler.open();
};
export const showSuccessMsg = (msg) => {
  var html = '<i class="fa fa-check-circle" aria-hidden="true"></i>'+msg  ;
  toastr.success(html, '', {
      timeOut: 4000,
      positionClass: "toast-bottom-right",
      escapeHtml: false // for set html in message
  });
}

export const showErrorMsg = (msg) => {
  var html = '<i class="fa fa-check-circle" aria-hidden="true"></i>'+msg  ;
  toastr.error(html, '', {
      timeOut: 4000,
      positionClass: "toast-bottom-right",
      escapeHtml: false // for set html in message
  });
}
