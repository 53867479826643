import React, { Component } from 'react';
import './App.scss';
// import LoginComponent from "./Login/LoginComponent.jsx";
import LoginComponent from "./Login/LoginComponent";
import DashboardComponent from "./Dashboard/DashboardComponent";

interface IState {
  ISLogin: boolean;
}

class App extends Component<{}, IState> {
  state: IState = {
    ISLogin: false,
  };

  componentDidMount() {
    if (localStorage.getItem("authToken")) {
      this.onLoginSuccess();    
    }
  }

  onLoginSuccess = () => {
    this.setState({
      ISLogin: true
    });
  }

  render() {
    const { ISLogin } = this.state;
    return (
      <div className="App">
        {
          ISLogin === false ?
            <LoginComponent onLoginSuccess={this.onLoginSuccess} /> :
            <DashboardComponent />
        }
      </div>
    );
  };
}

export default App;
