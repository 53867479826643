import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { default as WmsInput } from '../WmsInput/WmsInput';
import { default as WmsButton } from '../WmsButton/WmsButton';
import WmsContextMenu from '../WmsContextMenu/WmsContextMenu';

import * as GlobalService from '../../assets/js/global_services.js';
import { ObjectWms } from '../../assets/ts/ObjectWms';

import { KendoGridWmsVirtual } from '../../assets/ts/KendoGridWmsVirtual';
import "./GroupGridComponent.scss";

declare var window: any;
const { $ } = window;
const kendo = window.kendo

interface IProps {
    defaultColumns?: any,
    menu?: any,
    resetButtonLabel?: any,
    resetButtonIcon?: any,
    layoutData?: any,
    filterText?: any,
    orderSummary?: any,
    onGridSave?: any,
    totalResult?: any,
    loadAllMessageFirst?: any,
    showGridResult?: any,
    showGridSummary?: any,
    emptyRecoredText?: any,
    onLockedTableBindKeyEvent?: any,
    gridKeySelection?: string,
    schema?: any,
    allColumns?: any,
    config?: any,
    staticGridData?: any,
    exportable?: boolean,
    exportDisable?: boolean,
    exportGridDisabledText?: string,
    eventClickExportGrid?: any, // this should control export of grid
    gridName?: string,
    virtual?: boolean,
    id?: string, // this should write the id to the input
    wmsSelector?: string, // this should set up a selector on the input
    showResetButton?: boolean,
    languageHeaders?: any,
    windowId?: string,
    shawCheckbox?: boolean,
    showCombineCheckbox?: boolean,
    shawScanKeyInput?: boolean,
    disableScanKeyInput?: boolean,
    scanInputLoading?: boolean,
    OnScanValueEnter?: any,
    isScanKeyNotAvailable?: boolean,
    onSelection?: any, // this should control the selection of row event
    clickEventOfContextMenu?: any, // this should control the selection of row event
    filterObject?: any, // Object for filter grid
    getGridSelectionUrl?: string, // url of get selection list of records
    inlineFilter?: boolean, // this should control inline filter of grid or call api,
    inlineSelectAll?: boolean, // this should control inline all record selection of grid or call api,
    onSelectAllChange?: any, // this function call when selectall chane for inline selection,
    searchUrl?: string,
    gridEditable?: any, // set true or multiple edit column object to make grid columns editable
    showSortColumns?: boolean, // set true to shaw sotable columns in grid
    showColumnFilter?: boolean, // set true to shaw clear column filter in grid
    gridHeight?: string, // number for set grid height in px
    onLoadGrid?: any,
    onDataBound?: any,
    onGridFilterApplied?: any,
    loadAllRecords?: any,
    callForGetNewData?: any,
    eventClickColumns?: any,
    onEdit?: any,// this function call when grid column's edit
    onSort?: any,// this function call when grid column's sort
    onColumnReorder?: any,// this function call when grid column's order change
    onColumnResize?: any,// this function call when grid column's size change
    onClickClearSorts?: any,// this function call when click on clear sort button
    onClickResetGrid?: any,// this function call when click on reset default grid layout menu
    onCheckCombine?: any,// this function call when Check combine row checkbox
    virtualGridpageSize?: number,// set page size in virtual grid read api call
    isColumnFilterenable?: boolean, // set false to hide column filter option from in grid menu
    isColumnShowenable?: boolean, // set false to hide column display option from in grid menu
    exportExcelObj?: any, // set export excel config
    metaGridConfig?: any, // grid config meta data
    isAddBatchProperty?: boolean, // set true to add Batch property in grid data source obj
    isAlignIndexesByProperty?: boolean, // set true to  execute AlignIndexesByProperty() on default columns layout set
    metaDataFieldsName?: string, // it's use for save column data in meta data
    isNewColumnAdded?: boolean, // it's use for save column data in meta data
    isLegendSet?: boolean, // show legend for order allocation status
    isFindOrderSummary?: boolean, // show summary for find order
    csvExportFileName?: string, // it's use for save column data in meta data
    virtualGridDataLimit?: number, // set virtualGrigData limit default value is define in global service,
    showLoadingError?: boolean,
    groupKeyName?: string,
    onGridChange?: any,
}

interface IState {
    gridDomSelector?: any,
    sortArrayGrid?: any // default set of sorts on the grid
    dataBindingInitial?: number,
    intialiseKeyDown?: number,
    totalResult?: number,
    filterResult?: number,
    sortColums?: string,
    layoutData?: any,
    disabledText?: string,
    selectedOrders?: number,
    scanGridKey?: string,
    selectAll?: boolean,
    isCombineRows?: boolean,
    isHovering?: boolean,
    KendoGrid?: any,
    staticGridData?: any,
    filterObject?: any,
    transportData?: any,
    gridExpandIndex?: null,
    scrollPos?: number,
    columns?: any,
    schema?: any,
    sorts?: any,
    filter?: any,
    tooltipShow?: boolean,
    isLegendTooltipShow?: boolean,
    isAllDataLoad?: boolean,
    isImperial?: boolean,
    volumeType?: string,
    weightType?: string,
    orderSummary?: any,
    isExpandOrderSummary?: boolean
}

class GroupGridComponent extends PureComponent<IProps, IState>  {
    public static propTypes = {};
    public static defaultProps = {};

    constructor(props: IProps) {

        super(props);

        this.state = {
            gridDomSelector: () => props.windowId ? '.' + props.windowId + ' #' + props.id : '#' + props.id,
            sortArrayGrid: [], // default set of sorts on the grid
            dataBindingInitial: 0,
            intialiseKeyDown: 0,
            totalResult: 0,
            filterResult: 0,
            sortColums: '',
            layoutData: props.layoutData,
            disabledText: "",
            selectedOrders: 0,
            scanGridKey: "",
            selectAll: false,
            isCombineRows: false,
            isHovering: false,
            KendoGrid: new KendoGridWmsVirtual(props.id, props.windowId, props.gridKeySelection, props.getGridSelectionUrl),
            staticGridData: [],
            filterObject: props.filterObject,
            transportData: {},
            gridExpandIndex: null,
            scrollPos: 0,
            columns: props.defaultColumns,
            schema: props.schema,
            sorts: [],
            filter: [],
            tooltipShow: false,
            isLegendTooltipShow: false,
            isAllDataLoad: false,
            isImperial: true,
            volumeType: "FT³",
            weightType: "LBS",
            orderSummary: {
                "TotVolume": 0,
                "TotWeight": 0,
                "TotPackages": 0,
                "TotPrimaryUnit": 0
            },
            isExpandOrderSummary: false
        }



    }

    componentDidMount() {
        var that = this;
        // clear grid menu dropdown
        $(this.state.gridDomSelector() + "menu").kendoMenu();

        // prevent columns sorting when resize columns
        var isResizeHandler = false;
        $(this.state.gridDomSelector()).on('mousedown', '.k-grid-header-wrap', (event: any) => {
            isResizeHandler = $(event.target).hasClass("k-resize-handle");
        });

        $(this.state.gridDomSelector()).on("mouseup", "th>a.k-link", function (e: any) {
            var sortArr = $(that.state.gridDomSelector()).data("kendoGrid").dataSource.sort();
            if (!(e.ctrlKey || e.metaKey) && !isResizeHandler) {
                that.setState({
                    sortArrayGrid: []
                });
                if (sortArr && sortArr.length > 1) {
                    $(that.state.gridDomSelector()).data("kendoGrid").dataSource.sort({});
                }
            }

        });

        // grid column config set
        if (this.props.metaGridConfig !== "") {
            var gridOptions = JSON.parse(this.props.metaGridConfig);
            var tempObject = this.props.defaultColumns;
            if (gridOptions.columns) {
                var fieldArray = gridOptions.columns.map((el: any) => el.field);
                let updateColumn: any[];
                updateColumn = [];
                this.props.allColumns.filter((column: any) => {
                    if (fieldArray.indexOf(column.field) !== -1) {
                        updateColumn.push(column);
                    } else if (column.locked && column.isShawRowNumberColumnInList !== true) {
                        updateColumn.push(column);
                    }
                    return true;
                });
                tempObject = ObjectWms.alignIndexesByPropertyVirtual(gridOptions.columns, updateColumn, "field");
            } else {
                tempObject = ObjectWms.alignIndexesByPropertyVirtual(gridOptions.columns, this.props.defaultColumns, "field")
            }

            this.setState({
                columns: tempObject,
                sorts: gridOptions.sorts,
                filter: (gridOptions.filter) ? gridOptions.filter : [],
                sortArrayGrid: gridOptions.sorts
            }, this.loadGrid);
        } else {
            this.loadGrid();
        }

        $('#' + this.props.wmsSelector + 'scanGridKey').keydown((e: any) => {
            if (e.keyCode === 13) {
                this.props.OnScanValueEnter(this.state.scanGridKey);

                // call summary function to set select order number in grid top header
                setTimeout(() => {
                    this.updateGridSummary();
                }, 300);
            }
        });


    }
    componentWillReceiveProps(nextProps: any) {
        if (typeof nextProps.staticGridData !== "undefined" && nextProps.staticGridData !== this.state.staticGridData) {
            var scrollPos = $(this.state.gridDomSelector() + " .k-scrollbar").scrollTop();
            if (typeof $(this.state.gridDomSelector()).data("kendoGrid") !== "undefined") {
                // $(this.state.gridDomSelector()).data("kendoGrid").dataSource.page(1);
            }
            this.setState({ dataBindingInitial: 0 });
            kendo.ui.progress($(this.state.gridDomSelector()), true);
            var mygrid = $(this.state.gridDomSelector()).data("kendoGrid");
            if (typeof mygrid !== "undefined") {
                this.eventSelectAll("deselect", null);
            }
            this.setState({
                staticGridData: nextProps.staticGridData,
                scrollPos: scrollPos
            }, this.readNewDataCall);
        }
        if (typeof nextProps.isAllDataLoad !== "undefined" && nextProps.isAllDataLoad !== this.state.isAllDataLoad) {
            this.setState({
                isAllDataLoad: nextProps.isAllDataLoad
            })
        }

        // Update columns for inventory combine
        if (this.state.schema !== nextProps.schema) {
            this.setState({ dataBindingInitial: 0 });
            this.setState({
                columns: nextProps.defaultColumns,
                // dataBindingInitial:0,
                intialiseKeyDown: 0,
                schema: nextProps.schema
            }, () => {
                this.loadGridAgain(nextProps);
            })
        }
        // update find order grid summary
        if (this.props.isFindOrderSummary && nextProps.orderSummary !== this.props.orderSummary) {
            // Set find order grid summary data according radio button from data
            this.setStateOfOrderSummary(nextProps.orderSummary);
        }
    }
    setStateOfOrderSummary = (data: any) => {
        this.setState({
            orderSummary: {
                "TotVolume": this.state.isImperial ? data.TotVolumeImperial : data.TotVolumeMetric,
                "TotWeight": this.state.isImperial ? data.TotWeightImperial : data.TotWeightMetric,
                "TotPackages": data.TotPackages,
                "TotPrimaryUnit": data.TotPrimaryUnit
            },
            volumeType: this.state.isImperial ? "FT³" : "M³",
            weightType: this.state.isImperial ? "LBS" : "KG",
        })
    }
    loadGridAgain = (nextProps: any) => {
        if ($(this.state.gridDomSelector()).data('kendoGrid')) {
            $(this.state.gridDomSelector()).kendoGrid('destroy').empty();
            if (nextProps.metaGridConfig !== "") {
                var gridOptions = JSON.parse(nextProps.metaGridConfig);
                var tempObject = nextProps.defaultColumns;
                if (gridOptions.columns) {
                    var fieldArray = gridOptions.columns.map((el: any) => el.field);
                    let updateColumn: any[];
                    updateColumn = [];
                    nextProps.allColumns.filter((column: any) => {
                        if (fieldArray.indexOf(column.field) !== -1) {
                            updateColumn.push(column);
                        } else if (column.locked) {
                            updateColumn.push(column);
                        }
                        return true;
                    });
                    tempObject = ObjectWms.alignIndexesByPropertyVirtual(gridOptions.columns, updateColumn, "field");
                } else {
                    tempObject = ObjectWms.alignIndexesByPropertyVirtual(gridOptions.columns, nextProps.defaultColumns, "field")
                }

                this.setState({
                    columns: tempObject,
                    sorts: gridOptions.sorts,
                    filter: (gridOptions.filter) ? gridOptions.filter : [],
                    sortArrayGrid: gridOptions.sorts
                }, () => {
                    this.loadGrid();
                    setTimeout(() => {
                        this.eventSelectAll("deselect", null);
                    }, 100);
                });
            } else {
                this.loadGrid();
            }

        }

        // $(this.state.gridDomSelector()).data('kendoGrid').refresh();
    }
    readNewDataCall = () => {
        $(this.state.gridDomSelector()).data("kendoGrid").dataSource.read();
        setTimeout(() => {
            $(this.state.gridDomSelector() + " .k-scrollbar").scrollTop(this.state.scrollPos);
            this.updateGridSummary();
            this.filterDataCount();
            kendo.ui.progress($(this.state.gridDomSelector()), false);
        }, 200);
    }

    loadGrid = () => {
        var that = this;
        var gridObj = {
            dataSource: {
                pageSize: 40,
                transport: {
                    read: function (e: any) {
                        e.success(that.state.staticGridData);
                    }
                },
                schema: this.state.schema,
                sort: this.state.sorts,
                filter: this.state.filter,
                groupable: true,
                group: [
                    { field: this.props.groupKeyName }
                ]
            },
            change: () => {
                this.props.onGridChange();
            },
            excel: this.props.exportExcelObj,
            excelExport: (e: any) => {
                var sheet = e.workbook.sheets[0];
                var grid = e.sender;
                var fields = grid.dataSource.options.fields;
                var fieldsModels = grid.dataSource.options.schema.model.fields;
                var columns = grid.columns;
                var dateCells = [];

                for (var i = 0; i < fields.length; i++) {
                    var currentField = fields[i].field;
                    var currentModel = fieldsModels[currentField];
                    if (currentModel && currentModel.type === "date") {
                        for (var j = 0; j < columns.length; j++) {
                            if (currentField === columns[j].field) {
                                dateCells.push(j);
                                break;
                            };
                        };
                    };
                };

                for (var rowIndex = 1; rowIndex < sheet.rows.length; rowIndex++) {
                    var row = sheet.rows[rowIndex];

                    for (var q = 0; q < dateCells.length; q++) {
                        var cellIndex = dateCells[q];
                        var value = row.cells[cellIndex].value;
                        var newValue = kendo.toString(kendo.parseDate(value), 'yyyy-MM-dd HH:mm:ss');
                        row.cells[cellIndex].value = newValue;
                    };
                };
            },
            scrollable: {
                virtual: true
            },
            height: this.props.gridHeight,
            sortable: {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true
            },
            selectable: "multiple",
            navigatable: true,
            filterable: true,
            inPlaceSort: true,
            batch: this.props.isAddBatchProperty,
            columnMenu: {
                sortable: false,
                columns: false,
                messages: {
                    settings: 'Filter'
                }
            },
            editable: this.props.gridEditable,
            sort: (e: any) => {
                e.preventDefault();
                GlobalService.sortDataset(that.state.sortArrayGrid, e.sort);
                var grid = $(this.state.gridDomSelector()).data("kendoGrid")
                grid.dataSource.sort(that.state.sortArrayGrid);
                this.props.onSort(e);
            },
            dataBound: (e: any) => {
                var sortArr = $(that.state.gridDomSelector()).data("kendoGrid").dataSource.sort();
                // update sortArrayGrid state when change grid sort outside of grid like from saved filter and sort
                if (JSON.stringify(that.state.sortArrayGrid) !== JSON.stringify(sortArr)) {
                    that.setState({
                        sortArrayGrid: sortArr
                    });
                }
                this.state.KendoGrid.repaintSelections();
                if (this.state.dataBindingInitial === 0) {
                    // setTimeout(() => {
                    //     this.gridClickEventHandler();
                    // }, 10);
                }
                this.props.onDataBound(e, this.state.KendoGrid);

                if (that.state.intialiseKeyDown === 0) {
                    var grid = $(this.state.gridDomSelector()).data('kendoGrid');
                    grid.table.on("keydown", function (e: any) {
                        if (e.keyCode === 38 || e.keyCode === 40) {
                            var dataItem = grid.dataItem($("#" + that.props.id + "_active_cell").closest("tr"));
                            var currentPage = grid.dataSource.page();
                            var currentNumberOfItems = grid.dataSource.view().length;
                            var row = $("#" + that.props.id + "_active_cell").closest("tr").index();
                            var col = grid.cellIndex($(e.target).closest('td'));
                            let isVirtualGrid = true;
                            that.state.KendoGrid.clickEventHandleSelection(e, currentPage, currentNumberOfItems, row, col, dataItem, grid.dataSource.data(), that.props.gridKeySelection, isVirtualGrid, function () {
                                that.updateGridSummary();

                                that.props.onSelection(that.state.KendoGrid);
                            });
                        }
                    });
                    that.props.onLockedTableBindKeyEvent();
                    this.setState({
                        intialiseKeyDown: 1
                    });
                }
                that.setState({
                    sortColums: GlobalService.getSortedColumnName(that.state.sortArrayGrid, this.props.allColumns),
                });

                if (window.friconix_update) {
                    window.friconix_update();
                }
            },
            // columnMenu: {
            //     messages: {
            //         settings: this.translate('Label_Column_Filter')
            //     }
            // },
            columnShow: (e: any) => {
                this.props.callForGetNewData();
            },
            filter: (e: any) => {
                that.state.KendoGrid.clearSelections(function () {
                    that.updateGridSummary();
                    that.state.KendoGrid.repaintSelections();
                });
                that.props.onSelection(that.state.KendoGrid);

                this.props.onGridFilterApplied(e.filter);
                this.filterDataCount();
            },
            columnReorder: (e: any) => {
                this.props.onColumnReorder(e);
            },
            columnResize: (e: any) => {
                this.props.onColumnResize(e);
            },
            save: function (data: any) {
                that.props.onGridSave(data);
            },
            edit: function (data: any) {
                that.props.onEdit(data);
            },
            resizable: true,
            reorderable: true,
            columns: this.state.columns
        };
        $(this.state.gridDomSelector()).kendoGrid(gridObj);


        // if (this.state.filter && this.state.filter.filters && this.state.filter.filters.length > 0) {
        //     this.props.onGridFilterApplied(this.state.filter);
        // }
    }
    filterDataCount = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        if (grid) {
            var dataSource = grid.dataSource;
            setTimeout(() => {
                this.setState({
                    filterResult: dataSource.total()
                });
            }, 100);
        }
    }
    gridClickEventHandler = () => {

        var grid = $(this.state.gridDomSelector()).data('kendoGrid');
        if (grid) {
            grid.table.unbind('click');
        }
        var that = this;
        if (grid) {

            var lockedTableSelector = '';
            if (grid.lockedTable) {
                grid.lockedTable.unbind('click');
                lockedTableSelector = grid.lockedTable;
            }

            $(grid.table).add(lockedTableSelector).on('click', function (e: any) {
                // Hack: prevent parent grid row selection when click on child grid row
                if ($(e.target).parents('.k-detail-row').length > 0) {
                    return;
                }
                // if it is a regular click or shift click we want to deselect everything if selectall is selection, $scope.EventSelectAll checks if this is
                // on or not so calling it doesn't guarantee execution it just checks and does it if it needs it
                // ctrl click we don't want to do it because it can add or deselect
                if (e.ctrlKey || e.metaKey) {
                    that.eventSelectAll("deselect", "dontclearselection");
                } else {
                    that.eventSelectAll("deselect", null);
                }
                that.state.KendoGrid.setSelectAllCheckBox(that.state.selectAll);

                var dataItem = grid.dataItem($(e.target).closest('tr'));
                var currentPage = grid.dataSource.page();
                var currentNumberOfItems = grid.dataSource.view().length;
                var row = $(e.target).closest('tr').index();
                var col = grid.cellIndex($(e.target).closest('td'));
                let isVirtualGrid = true;
                that.state.KendoGrid.clickEventHandleSelection(e, currentPage, currentNumberOfItems, row, col, dataItem, grid.dataSource.data(), that.props.gridKeySelection, isVirtualGrid, function () {
                    that.updateGridSummary();
                    that.props.onSelection(that.state.KendoGrid);
                });
            });
        }


        this.setState({
            dataBindingInitial: 1
        })
    }


    onChangeFunc(event: any) {
        var that = this;
        this.setState({
            selectAll: event.target.checked
        });
        setTimeout(function () {
            that.eventSelectAll(null, null);
        }, 0);
    }

    onCheckCombine = (event: any) => {
        this.setState({
            isCombineRows: event.target.checked
        });
        this.props.onCheckCombine(event.target.checked);
    }

    eventSelectAll(pass: any, dontclearselection: any) {
        var that = this;
        if (pass === "deselect") {
            if (that.state.selectAll === true) {
                that.setState({ selectAll: false }); // clear select all checkbox
                if (dontclearselection === "dontclearselection") {
                    // we just want to clear the select box above here
                } else {
                    that.state.KendoGrid.clearSelections(function () {
                        that.updateGridSummary();
                        that.state.KendoGrid.repaintSelections();
                    });
                }
            }
        } else {
            if (that.state.selectAll === false) {
                that.state.KendoGrid.clearSelections(function () {
                    that.updateGridSummary();
                    that.state.KendoGrid.repaintSelections();
                });
            } else if (that.state.selectAll === true) {
                if (this.props.inlineSelectAll) {
                    var mygrid = $(this.state.gridDomSelector()).data("kendoGrid");
                    if (typeof mygrid != "undefined") {
                        mygrid.select("tr");
                    }
                } else {
                    that.state.KendoGrid.clearSelections(function () {
                        var dataSource = $(that.state.gridDomSelector()).data("kendoGrid").dataSource;
                        var filteredDataSource = new kendo.data.DataSource({
                            data: dataSource.data(),
                            filter: dataSource.filter(),
                            sort: dataSource.sort()
                        });
                        filteredDataSource.read();
                        var data = filteredDataSource.view();
                        that.state.KendoGrid.addMassToSelection(data);
                        that.updateGridSummary();
                    });
                }
            }
            that.props.onSelection(that.state.KendoGrid);
        }
        if (this.props.inlineSelectAll) {
            this.props.onSelectAllChange();
        }
    }

    updateGridSummary = () => {
        this.setState({ selectedOrders: this.state.KendoGrid.getNumberOfSelections() });
    }


    eventClickColumns = () => {
        // set dataBindingInitial 0 for bind click event on  dataBound:

        this.setState({
            dataBindingInitial: 0
        })
        this.props.eventClickColumns();
    }

    EventClickResetGrid = () => {
        var that = this;
        kendo.ui.progress($(this.state.gridDomSelector()), true);

        this.setState({
            dataBindingInitial: 0,
            intialiseKeyDown: 0,
            sortColums: '',
            sortArrayGrid: []
        })
        var column = {
            columns: this.props.defaultColumns
        };
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        grid.setOptions(column);
        grid.dataSource.sort({});

        this.state.KendoGrid.clearSelections(function () {
            that.state.KendoGrid.repaintSelections();
        });
        kendo.ui.progress($(this.state.gridDomSelector()), false);
        this.updateGridSummary();
        this.props.callForGetNewData();
        this.props.onSelection(this.state.KendoGrid);
        this.props.onClickResetGrid();

    }

    // onOpenKendoMenu = () => {
    //     this.refs.VirtualGridWmsContextMenu.closeWmsContextMenu();
    // }

    EventClickClearFilters = () => {
        var that = this;
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        grid.dataSource.filter({});
        this.eventSelectAll("deselect", null);
        that.state.KendoGrid.clearSelections(function () {
            that.updateGridSummary();
            that.state.KendoGrid.repaintSelections();
            that.props.onSelection(that.state.KendoGrid);
        });

        this.props.onGridFilterApplied();
    }

    EventClickClearSorts = () => {
        var gridData = $(this.state.gridDomSelector()).data('kendoGrid');
        gridData.dataSource.sort({});
        this.setState({
            sortColums: '',
            sortArrayGrid: []
        });
        this.props.onClickClearSorts();
    }

    eventClickContextMenu = (obj: any, e: any) => {
        // prevent click of parent menu in context menu
        if ($(e.target).parent().attr('has-child-menu') === 'true') {
            return true;
        }
        this.props.clickEventOfContextMenu(obj, e);
    }

    eventMouseEnter = (text: any, baseCall: any) => {
        if (text === "You cannot auto batch order. Please contact your administrator.") {

        }
        else if (this.state.selectedOrders === 0 && baseCall !== 'FromResetGridOptions') {
            return;
        }
        this.setState({
            disabledText: text
        });
    }

    eventMouseLeave = () => {
        this.setState({
            disabledText: ""
        })
    }
    // method for context menu right click
    beforeContextMenuOpen = (e: any) => {
        //Hack: prevent context menu when click on row detail section
        if ($(e.target).parents('.k-detail-row').length > 0 || $(e.target).hasClass('k-detail-row')) {
            e.preventDefault();
            return;
        }

        var grid = $(this.state.gridDomSelector()).data('kendoGrid');
        var dataItem = grid.dataItem($(e.target).closest('tr'));

        var page = grid.dataSource.page();
        var length = grid.dataSource.view().length;
        var col = grid.cellIndex($(e.target).closest('td'));

        var row2 = $(e.target).closest('tr').index();
        var that = this;
        this.state.KendoGrid.onRightClick(e, page, length, row2, col, dataItem, grid.dataSource.data(), this.props.gridKeySelection, function () {
            that.updateGridSummary();
            that.props.onSelection(that.state.KendoGrid, 'contextMenuClick');
        });

    }
    handleMouseHover = () => {
        this.setState({ isHovering: true });
    }

    handleMouseLeave = () => {
        this.setState({ isHovering: false });
    }
    exportGridToCsv = () => {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        var dataSource = grid.dataSource;
        var data = dataSource.data();
        var sort = dataSource.sort();
        var query = new kendo.data.Query(data);
        var filter = dataSource.filter();
        var sortedData = query.sort(sort).filter(filter).data;
        GlobalService.exportGridtoCSV(sortedData, this.state.gridDomSelector(), this.props.csvExportFileName);
    }

    changeSummaryType = (event: any) => {
        // change type
        this.setState({
            isImperial: !this.state.isImperial
        }, () => {
            this.setStateOfOrderSummary(this.props.orderSummary)
        })
    }
    orderSummaryExpand = (param_flag: any) => (event: any) => {

        var content = $(".wms-findorder-grid-details");
        content.slideToggle(500, () => {
            this.setState({ isExpandOrderSummary: param_flag });
        });
    }
    handleScanGridKeyChange = (e: any) => {
        this.setState({ scanGridKey: e.target.value });
    }
    // setFocusOnScanInput = (e: any) => {
    //    this.refs.scanGridKey.refs.myinput.focus();
    // }
    cleanScanInput = (e: any) => {
        this.setState({ scanGridKey: '' })
    }


    render() {
        var grid = $(this.state.gridDomSelector()).data('kendoGrid');
        var currentNumberOfItems = 0;
        if (grid) {
            currentNumberOfItems = grid.dataSource.view().length;
        }
        return (
            <div className="wms-group-grid-holder">
                <div className="wms_area_content">
                    <div className="wms_toolbar">
                        {this.props.shawCheckbox && (
                            <div className="button_list">
                                <div className="checkbox">
                                    <div className="wms_checkbox_wrapper">
                                        <label htmlFor={this.props.wmsSelector + "virtualGridSelectAll"} >
                                            <input
                                                className="option_input wms-option-input"
                                                type="checkbox"
                                                id={this.props.wmsSelector + "virtualGridSelectAll"}
                                                data-wms-selector={this.props.wmsSelector + '_SelectAll'}
                                                onChange={this.onChangeFunc.bind(this)}
                                                checked={this.state.selectAll}
                                            />{" "}
                                            {'Select All'}
                                        </label>
                                        {/* {this.props.totalResult >= GlobalService.constants.virtualizedGridLimit && (<div>
                                            <div className="div_disable" onMouseEnter={this.handleMouseHover}
                                                onMouseLeave={this.handleMouseLeave}>&nbsp;</div>
                                            {this.state.isHovering == true && (<div onMouseEnter={this.handleMouseHover} onMouseLeave={this.handleMouseLeave} className="div_tooltip">To enable, click less than {GlobalService.constants.virtualizedGridLimit}</div>)}
                                        </div>)} */}
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.props.shawScanKeyInput && (
                            <div className="scan-key-wrapper">
                                <WmsInput
                                    id={this.props.wmsSelector + 'scanGridKey'}
                                    extraWrapperClass="full-width-input"
                                    inputName={this.props.wmsSelector + 'scanGridKey'}
                                    wmsSelector={this.props.wmsSelector + 'scanGridKey'}
                                    label={''}
                                    value={this.state.scanGridKey}
                                    onChangeFunc={this.handleScanGridKeyChange}
                                    onIconClick={this.handleScanGridKeyChange}
                                    showIconAfterInput={true}
                                    icon={(this.props.scanInputLoading) ? "fa-spinner fa-pulse" : "fa-search"}
                                    isAutoFocus={true}
                                    ref="scanGridKey"
                                    disabled={this.props.disableScanKeyInput}
                                />
                                {this.props.isScanKeyNotAvailable && (
                                    <div className='scan-key-not-found'> { 'Recored not found'} </div>
                                )}
                            </div>
                        )}
                        {this.props.showCombineCheckbox && (
                            <div className="button_list">
                                <div className="checkbox">
                                    <div className={"wms_checkbox_wrapper " + (currentNumberOfItems === 0 ? 'wms_checkbox_disabled' : '')}>
                                        <label htmlFor="virtualGridCombine" >
                                            <input
                                                className="option_input wms-option-input"
                                                type="checkbox"
                                                id="virtualGridCombine"
                                                data-wms-selector={this.props.wmsSelector + '_CombineRow'}
                                                onChange={this.onCheckCombine}
                                                checked={this.state.isCombineRows}
                                                disabled={currentNumberOfItems === 0}
                                            />{" "}
                                            {'Identical rows'}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}
                        <WmsContextMenu
                            eventClickContextMenu={this.eventClickContextMenu}
                            attachTo={this.state.gridDomSelector()}
                            menu={this.props.menu}
                            eventMouseEnter={this.eventMouseEnter}
                            eventMouseLeave={this.eventMouseLeave}
                            onBeforeOpen={(e: any) => this.beforeContextMenuOpen(e)}
                        // contextMenuFilter={this.props.contextMenuFilter} 
                        />
                        <div className="wms-toolbar-button ">
                            {this.props.menu.map((obj: any, index: any) =>
                                (typeof obj.isButtonMenu === "undefined" || obj.isButtonMenu === true) ?
                                    obj.value.length !== 0 ?
                                        // <WmsButtonDropdown
                                        //     onOpenKendoMenu={this.onOpenKendoMenu}
                                        //     windowId={this.props.windowId}
                                        //     key={index}
                                        //     wmsSelector={obj.wmsSelector}
                                        //     id={obj.name.split(' ').join('')}
                                        //     disabled={obj.disabled}
                                        //     label={obj.name}
                                        //     menuIcon={obj.icon}
                                        //     isHidden={obj.isHidden ? obj.isHidden : false}
                                        //     buttonType={obj.color}>
                                        //     <WmsMenuItem
                                        //         eventMouseEnter={this.eventMouseEnter}
                                        //         eventMouseLeave={this.eventMouseLeave}
                                        //         items={obj.value} />
                                        // </WmsButtonDropdown>
                                        ''
                                        :
                                        (obj.isButtonShaw !== false &&
                                            <WmsButton
                                                key={index}
                                                wmsSelector={obj.wmsSelector}
                                                disabled={obj.disabled}
                                                icon={obj.icon}
                                                label={obj.name}
                                                buttonType={obj.color}
                                                onClickFunc={obj.clickFunc}
                                                loading={obj.loading}
                                                showToolTip={obj.showToolTip}
                                                tooltipText={obj.tooltipText}
                                                parentClassName={obj.parentClassName ? obj.parentClassName : ''} />
                                        )
                                    : ""
                            )}
                            {this.props.showResetButton === true &&
                                (<div className="reset_btn">
                                    <ul
                                        kendo-menu={this.props.id + "kendoMenu"}
                                        id={this.props.id + "menu"}
                                        k-orientation="'horizontal'"
                                        className="GridMenu"
                                        data-wms-selector={this.props.wmsSelector + '_resetMenu'}
                                    >
                                        <li className="GridMenuTopLink">
                                            <span className="options-title"> <i className="fi-xnsuxl-three-bars-solid" aria-hidden="true"></i> <span className="virtual-grid-options">Options</span></span>
                                            <ul>
                                                {/* <li onClick={this.eventClickColumns} data-wms-selector={this.props.wmsSelector + '_columns'}>
                                                    <span>{this.translate("Label_Columns")}</span>
                                                </li> */}
                                                <li onClick={this.EventClickResetGrid} data-wms-selector={this.props.wmsSelector + '_reset'}>
                                                    <span>Reset Default Grid Layout</span>
                                                </li>
                                                {this.props.showColumnFilter && (
                                                    <li onClick={this.EventClickClearFilters} data-wms-selector={this.props.wmsSelector + '_clearFilter'}>
                                                        <span>Clear Column Filters</span>
                                                    </li>)
                                                }
                                                {this.props.showSortColumns && (
                                                    <li onClick={this.EventClickClearSorts} data-wms-selector={this.props.wmsSelector + '_clearSort'}>
                                                        <span>Clear Sort</span>
                                                    </li>)
                                                }
                                                {/* {this.props.exportable ? (
                                                    <li
                                                        onMouseLeave={
                                                            this.props.exportDisable == true ?
                                                                this.eventMouseLeave :
                                                                () => function () { }
                                                        }
                                                        onMouseEnter={
                                                            this.props.exportDisable == true ?
                                                                () => this.eventMouseEnter(this.props.exportGridDisabledText,'FromResetGridOptions') :
                                                                () => function () { }
                                                        }
                                                        onClick={this.props.exportDisable ? () => function () { } : this.props.eventClickExportGrid}
                                                        data-wms-selector={this.props.wmsSelector + '_exportExcelGrid'}
                                                        className={(this.props.exportDisable ? 'wms-disabled-look' : 'k-state-default') + ' k-item ExportGrid'}
                                                    >
                                                        <span>{this.translate("Label_Export_Excel_Grid")}</span>
                                                    </li>
                                                ) : null} */}
                                                {/* {this.props.exportable ? (
                                                    <li
                                                        onMouseLeave={
                                                            this.props.exportDisable == true ?
                                                                this.eventMouseLeave :
                                                                () => function () { }
                                                        }
                                                        onMouseEnter={
                                                            this.props.exportDisable == true ?
                                                                () => this.eventMouseEnter(this.props.exportGridDisabledText,'FromResetGridOptions') :
                                                                () => function () { }
                                                        }
                                                        test="Helloooo"
                                                        onClick={this.props.exportDisable ? () => function () { } : this.exportGridToCsv}
                                                        data-wms-selector={this.props.wmsSelector + '_exportCsvGrid'}
                                                        className={(this.props.exportDisable ? 'wms-disabled-look' : 'k-state-default') + ' k-item ExportGrid'}
                                                    >
                                                        <span>{this.translate("Label_Export_CSV_Grid")}</span>
                                                    </li>
                                                ) : null} */}
                                            </ul>
                                        </li>
                                    </ul>
                                </div>)
                            }
                        </div>
                    </div>

                    <div className="wms-grid-details">
                        {this.props.showGridResult === true &&
                            <label className="wms_grid_results">
                                <span>{this.props.totalResult}</span>{"Results"}
                            </label>
                        }

                        {this.props.showGridSummary &&
                            <label className="wms_grid_selected">
                                <span>{this.state.selectedOrders}</span>{"Label_Grid_Selected"}
                            </label>
                        }

                        {this.state.sortColums !== "" && this.props.showSortColumns && (
                            <label className="sort"> Sort: <span className="no-space">{this.state.sortColums}</span> </label>
                        )}

                        {/* {this.props.filterText != '' && (
                            <span className="wms-filter-text"  >
                                <span>{this.state.filterResult} Results With </span>
                                <span>{this.props.filterText}</span>
                                <div className="wms-filter-tooltip">
                                    <i className="fa fa-info-circle show_more"
                                        onMouseOver={() => this.setState({ tooltipShow: true })}
                                        onMouseOut={() => this.setState({ tooltipShow: false })}
                                    ></i>
                                    {this.state.tooltipShow && (
                                        <div className="wms-filter-tooltip-content">
                                            <span dangerouslySetInnerHTML={{ __html: this.props.fullFilterText }} />
                                        </div>
                                    )}
                                </div>
                            </span>
                        )} */}
                        <div className="grid-right-content">
                            {this.props.isLegendSet && (
                                <div className="wms-legend-tooltip wms-filter-tooltip">
                                    <i className="fa fa-info-circle show_more"
                                        onMouseOver={() => this.setState({ isLegendTooltipShow: true })}
                                        onMouseOut={() => this.setState({ isLegendTooltipShow: false })}
                                    ></i>
                                    {this.state.isLegendTooltipShow && (
                                        <div className="wms-filter-tooltip-content">
                                            <ul>
                                                <li><span className="wms-color-box1"></span><span>No Line Items</span></li>
                                                <li><span className="wms-color-box2"></span><span>Unallocated</span></li>
                                                <li><span className="wms-color-box3"></span><span>Partially Allocated</span></li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            )}
                            {this.props.isFindOrderSummary && (
                                <div className="wms-filter-tooltip">
                                    {this.state.isExpandOrderSummary === false ? (
                                        <i className="fa fa-chevron-down"
                                            onClick={this.orderSummaryExpand(true)}
                                        ></i>
                                    ) : (
                                        <i className="fa fa-chevron-up"
                                            onClick={this.orderSummaryExpand(false)}
                                        ></i>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {this.props.isFindOrderSummary && (
                        <div className="wms-findorder-grid-details" style={{ display: this.state.isExpandOrderSummary ? 'flex' : 'none' }}>
                            <div className="wms-details-data-section">
                                <p className="details-count">{this.state.orderSummary.TotVolume ? this.state.orderSummary.TotVolume.toFixed(4) : "0.0000"}</p>
                                <p className="details-name">Total Volume ({this.state.volumeType})</p>
                            </div>
                            <div className="wms-details-data-section">
                                <p className="details-count">{this.state.orderSummary.TotWeight ? this.state.orderSummary.TotWeight.toFixed(4) : "0.0000"}</p>
                                <p className="details-name">Total Weight ({this.state.weightType})</p>
                            </div>
                            <div className="wms-details-data-section">
                                <p className="details-count">{this.state.orderSummary.TotPackages}</p>
                                <p className="details-name">Total Packages</p>
                            </div>
                            <div className="wms-details-data-section">
                                <p className="details-count">{this.state.orderSummary.TotPrimaryUnit}</p>
                                <p className="details-name">Total Primary Units</p>
                            </div>
                            {/* <div className="wms-details-radio-section">
                                <WmsRadio
                                    id="IsImperial"
                                    name="IsImperial"
                                    label={this.translate('Label_US_Imperial')}
                                    wmsSelector={this.props.wmsSelector + 'IsImperial'}
                                    onChangeFunc={this.changeSummaryType}
                                    value={this.state.isImperial}
                                    checked={this.state.isImperial == true}
                                />

                                <WmsRadio
                                    id="metric"
                                    name="IsImperial"
                                    label={this.translate('Label_Metric')}
                                    wmsSelector={this.props.wmsSelector + 'Metric'}
                                    onChangeFunc={this.changeSummaryType}
                                    value={this.state.isImperial}
                                    checked={this.state.isImperial != true}
                                />
                            </div> */}
                        </div>
                    )}
                </div>

                <div className="wms-grid-area-wrapper">
                    <div className="wms_area_border">
                        <div id="gridContainer" className="gridContainer">
                            <div
                                className="no-josh"
                                id={this.props.id}
                                data-wms-selector={this.props.wmsSelector}
                            />
                        </div>
                    </div>
                </div>
            </div >
        );
    }
};
GroupGridComponent.propTypes = {
    defaultColumns: PropTypes.array,
    allColumns: PropTypes.array,
    config: PropTypes.object,
    staticGridData: PropTypes.array,
    exportable: PropTypes.bool,
    exportDisable: PropTypes.bool,
    exportGridDisabledText: PropTypes.string,
    eventClickExportGrid: PropTypes.func, // this should control export of grid
    gridName: PropTypes.string,
    virtual: PropTypes.bool,
    id: PropTypes.string, // this should write the id to the input
    wmsSelector: PropTypes.string, // this should set up a selector on the input
    languageHeaders: PropTypes.array,
    windowId: PropTypes.string,
    shawCheckbox: PropTypes.bool,
    showCombineCheckbox: PropTypes.bool,
    shawScanKeyInput: PropTypes.bool,
    disableScanKeyInput: PropTypes.bool,
    scanInputLoading: PropTypes.bool,
    OnScanValueEnter: PropTypes.func,
    isScanKeyNotAvailable: PropTypes.bool,
    onSelection: PropTypes.func, // this should control the selection of row event
    clickEventOfContextMenu: PropTypes.func, // this should control the selection of row event
    filterObject: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]), // Object for filter grid
    getGridSelectionUrl: PropTypes.string, // url of get selection list of records
    inlineFilter: PropTypes.bool, // this should control inline filter of grid or call api,
    inlineSelectAll: PropTypes.bool, // this should control inline all record selection of grid or call api,
    onSelectAllChange: PropTypes.func, // this function call when selectall chane for inline selection,
    searchUrl: PropTypes.string,
    gridEditable: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]), // set true or multiple edit column object to make grid columns editable
    showSortColumns: PropTypes.bool, // set true to shaw sotable columns in grid
    showColumnFilter: PropTypes.bool, // set true to shaw clear column filter in grid
    gridHeight: PropTypes.string, // number for set grid height in px
    onLoadGrid: PropTypes.func,
    onDataBound: PropTypes.func,
    onGridFilterApplied: PropTypes.func,
    loadAllRecords: PropTypes.func,
    callForGetNewData: PropTypes.func,
    eventClickColumns: PropTypes.func,
    onEdit: PropTypes.func,// this function call when grid column's edit
    onSort: PropTypes.func,// this function call when grid column's sort
    onColumnReorder: PropTypes.func,// this function call when grid column's order change
    onColumnResize: PropTypes.func,// this function call when grid column's size change
    onClickClearSorts: PropTypes.func,// this function call when click on clear sort button
    onClickResetGrid: PropTypes.func,// this function call when click on reset default grid layout menu
    onCheckCombine: PropTypes.func,// this function call when Check combine row checkbox
    virtualGridpageSize: PropTypes.number,// set page size in virtual grid read api call
    isColumnFilterenable: PropTypes.bool, // set false to hide column filter option from in grid menu
    isColumnShowenable: PropTypes.bool, // set false to hide column display option from in grid menu
    exportExcelObj: PropTypes.object, // set export excel config
    metaGridConfig: PropTypes.string, // grid config meta data
    isAddBatchProperty: PropTypes.bool, // set true to add Batch property in grid data source obj
    isAlignIndexesByProperty: PropTypes.bool, // set true to  execute AlignIndexesByProperty() on default columns layout set
    metaDataFieldsName: PropTypes.string, // it's use for save column data in meta data
    isNewColumnAdded: PropTypes.bool, // it's use for save column data in meta data
    isLegendSet: PropTypes.bool, // show legend for order allocation status
    isFindOrderSummary: PropTypes.bool, // show summary for find order
    csvExportFileName: PropTypes.string, // it's use for save column data in meta data
    virtualGridDataLimit: PropTypes.number, // set virtualGrigData limit default value is define in global service,
    showLoadingError: PropTypes.bool

}

GroupGridComponent.defaultProps = {
    exportable: false,
    exportDisable: false,
    exportGridDisabledText: '',
    shawCheckbox: true,
    showCombineCheckbox: false,
    shawScanKeyInput: false,
    disableScanKeyInput: false,
    isScanKeyNotAvailable: false,
    scanInputLoading: false,
    OnScanValueEnter: (e: any) => function () { },
    inlineFilter: false,
    inlineSelectAll: false,
    gridEditable: false,
    showResetButton: true,
    showGridResult: true,
    gifLoaderText: '',
    showSortColumns: true,
    showColumnFilter: true,
    gridHeight: '99%',
    onLoadGrid: (e: any) => function () { },
    onGridBinding: (e: any) => function () { },
    onGridChange: (e: any) => function () { },
    onGridSave: (e: any) => function () { },
    onDataBound: (e: any) => function () { },
    onEdit: (e: any) => function () { },
    onSort: (e: any) => function () { },
    onColumnReorder: (e: any) => function () { },
    onColumnResize: (e: any) => function () { },
    onClickClearSorts: (e: any) => function () { },
    onClickResetGrid: (e: any) => function () { },
    onSelectAllChange: (e: any) => function () { },
    onGridFilterApplied: (e: any) => function () { },
    loadAllRecords: (e: any) => function () { },
    callForGetNewData: (e: any) => function () { },
    eventClickColumns: (e: any) => function () { },
    onCheckCombine: (e: any) => function () { },
    onLockedTableBindKeyEvent: (e: any) => function () { },
    virtualGridpageSize: 50,
    isColumnFilterenable: true,
    isColumnShowenable: true,
    exportExcelObj: {},
    metaGridConfig: "",
    isAlignIndexesByProperty: false,
    isAddBatchProperty: false,
    isNewColumnAdded: false,
    isLegendSet: false,
    isFindOrderSummary: false,
    csvExportFileName: 'orderGridExport',
    virtualGridDataLimit: GlobalService.constants.virtualGridDataLimit,
    emptyRecoredText: "",
    groupKeyName: "",
    showLoadingError: false
}

export default GroupGridComponent;
