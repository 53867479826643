import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import "./WmsInput.scss";


interface IBaseProps {
    id?: string; // this should write the id to the input
    label?: string;// this should be the label that default shows and floats up to the top
    disabled?: boolean; // default will be false
    maxLength?: number, // this is the maxLength of the input
    required?: boolean; // this determines if the input is required and shows in red
    wmsSelector?: string; // this should set up a selector on the input
    parentClassName?: string; //    this should parent tag class
    extraWrapperClass?: string; // extra wrapper class if needed
    regularExp?: string; // extra wrapper class if needed
    valueType?: string; // alphanumeric, number, letters
    value?: any; // this should control the value of
    zeroBasedCondition?: number; // Allowed values 1,2,3
    // 1 ==> Normal Input Field
    // 2 ==> Allowed Zero
    // 3 ==> Allowed Zero and Required Zero Validation
    onChangeFunc?: (event: any) => void, // this should control the onchange event and anything we need to determine before we send it up
    //theme??: PropTypes.string, // this should control the theme of the input,
    showToolTip?: boolean; // set tooltip show or not
    showIconAfterInput?: boolean;
    inputType?: string;
    inputName?: string;
    placeholder?: string;
    tooltipText?: string; // show text on hover as tooltip
    extraSignBeforeValue?: string; // show extra sign before value like $
    icon?: string;
    isAutoFocus?: any;
    onIconClick?: (event: any) => void,
    onBlurFunc?: (event: any) => void,
    onKeyPressFunc?: (event: any) => void,
    onMouseEnterFunc?: (event: any) => void,
    onMouseLeaveFunc?: (event: any) => void,
    onKeyDown?: (event: any) => void
}
interface IState {
    value?: string;
    isHovering?: boolean;
}


type IProps = IBaseProps;

class WmsInput extends PureComponent<IProps, IState> {
    public static propTypes = {};
    public static defaultProps = {};
    constructor(props: IProps) {
        super(props);
    
        this.state = {
            value: props.value || '',
            isHovering: false
        };
    }
    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.zeroBasedCondition === 2 || nextProps.zeroBasedCondition === 3) {
            return {
                value: (nextProps.value !== '' && nextProps.value !== null && nextProps.value !== undefined) ? nextProps.value : ""
            };
        } else {
            return {
                value: nextProps.value || ""
            };
        }
    }
    onChangeFunc = (e: any) => {
        var valueTypeMatch = true;
        if (e.target.value === '' || valueTypeMatch) {
            this.setState({
                value: e.target.value
            })
            if (this.props.onChangeFunc) {
                this.props.onChangeFunc(e);
            }
        }
    }

    handleMouseHover() {
        this.setState({ isHovering: !this.state.isHovering });
    }
    render() {

        var isRequiredClass = '';
        if (this.props.zeroBasedCondition === 1) {
            isRequiredClass = (!this.props.value && this.props.required) ? "wms_required_input" : '';
        } else if (this.props.zeroBasedCondition === 2) {
            isRequiredClass = (!(this.props.value !== '' && this.props.value !== null && this.props.value !== undefined) && this.props.required) ? "wms_required_input" : ''
        } else if (this.props.zeroBasedCondition === 3) {
            isRequiredClass = (!(this.props.value !== "0" && this.props.value !== 0 && this.props.value !== '' && this.props.value !== null && this.props.value !== undefined) && this.props.required) ? "wms_required_input" : ''
        }
        const {
            extraWrapperClass, extraSignBeforeValue, parentClassName, label, id, inputType,
            inputName, placeholder, onKeyPressFunc, onBlurFunc, maxLength, required, disabled, onMouseEnterFunc, onMouseLeaveFunc, onKeyDown
        } = this.props;
        return (
            <div className={'wms-input-component ' + extraWrapperClass + (extraSignBeforeValue !== "" ? ' input-extra-sign-component' : '')} >
                <div className={this.state.value !== "" ? `has_value ${parentClassName}` : parentClassName}>
                    <label className="inputlabel" htmlFor={id}>{label}</label>
                    {extraSignBeforeValue !== "" && (
                        <span className="extra-sign">
                            {extraSignBeforeValue}
                        </span>
                    )}
                    <input
                        type={inputType}
                        id={id}
                        name={inputName}
                        className={isRequiredClass}
                        placeholder={placeholder}
                        value={this.state.value}
                        onChange={this.onChangeFunc}
                        onKeyPress={onKeyPressFunc}
                        onBlur={onBlurFunc}
                        maxLength={maxLength}
                        required={required}
                        disabled={disabled}
                        onMouseEnter={onMouseEnterFunc}
                        onMouseLeave={onMouseLeaveFunc}
                        autoComplete="off"
                        onKeyDown={onKeyDown}
                    />
                    {
                        this.props.disabled === true &&
                        this.props.showToolTip === true &&
                        (<div>
                            <div className="div_disable" onMouseEnter={this.handleMouseHover}
                                onMouseLeave={this.handleMouseHover}>&nbsp;</div>
                            {this.state.isHovering === true && <div className="div_tooltip">{this.props.tooltipText}</div>}
                        </div>)
                    }
                </div>

                {this.props.showIconAfterInput && (
                    <span className="wms-search-input-icon" onClick={this.props.onIconClick}>
                        <i className={this.props.icon} aria-hidden="true"></i>
                    </span>
                )}
            </div>
        );
    }
}

WmsInput.propTypes = {
    id: PropTypes.string, // this should write the id to the input
    label: PropTypes.string.isRequired,// this should be the label that default shows and floats up to the top
    disabled: PropTypes.bool, // default will be false
    maxLength: PropTypes.number, // this is the maxLength of the input
    required: PropTypes.bool, // this determines if the input is required and shows in red
    parentClassName: PropTypes.string, //    this should parent tag class
    extraWrapperClass: PropTypes.string, // extra wrapper class if needed
    valueType: PropTypes.string, // alphanumeric, number, letters
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]), // this should control the value of
    zeroBasedCondition: PropTypes.number, // Allowed values 1,2,3
    // 1 ==> Normal Input Field
    // 2 ==> Allowed Zero
    // 3 ==> Allowed Zero and Required Zero Validation
    onChangeFunc: PropTypes.func.isRequired, // this should control the onchange event and anything we need to determine before we send it up
    //theme??: PropTypes.string, // this should control the theme of the input,
    showToolTip: PropTypes.bool, // set tooltip show or not
    tooltipText: PropTypes.string, // show text on hover as tooltip
    extraSignBeforeValue: PropTypes.string, // show extra sign before value like $
    icon: PropTypes.string,
    isAutoFocus: PropTypes.bool,
    onIconClick: PropTypes.func,
    onBlurFunc: PropTypes.func,
    onKeyPressFunc: PropTypes.func,
    onMouseEnterFunc: PropTypes.func,
    onMouseLeaveFunc: PropTypes.func,
    onKeyDown: PropTypes.func

}

WmsInput.defaultProps = {
    disabled: false,
    maxLength: 255,
    required: false,
    showToolTip: false,
    showIconAfterInput: false,
    tooltipText: 'add tooltip text',
    extraSignBeforeValue: '',
    valueType: "",
    zeroBasedCondition: 1,
    parentClassName: "form_group",
    extraWrapperClass: "",
    inputType: 'text',
    regularExp: '',
    placeholder: '',
    isAutoFocus: false,
    onBlurFunc: () => void (0),
    onKeyPressFunc: () => void (0),
    onMouseEnterFunc: () => void (0),
    onMouseLeaveFunc: () => void (0),
    onKeyDown: () => void (0)
}

export default WmsInput;
