
import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import WmsContextMenuItem from '../WmsContextMenuItem/WmsContextMenuItem';
import './WmsContextMenu.scss';

let textareaElement: any, container: any;
declare var window: any;
const { $ } = window;
interface IProps {
    attachTo?: string,
    eventClickContextMenu?: any,
    menu?: any,
    contextMenuFilter?: string,
    eventMouseEnter?: any,
    eventMouseLeave?: any,
    onBeforeOpen?: any
}

interface IState {
    uniqueId?: string,
    isOpen?: boolean,
    clipBoardText?: string,
    clipBoardTextRow?: string
}
class WmsContextMenu extends PureComponent<IProps, IState> {

    public static propTypes = {};
    public static defaultProps = {};

    constructor(props: IProps) {

        super(props);

        this.state = {
            uniqueId: "random" + Math.floor((Math.random() * 1000) + 1) + "" + Date.now(),
            isOpen: false,
            clipBoardText: '',
            clipBoardTextRow: ''

        }
        this.createContainerElement();
    };
    createContainerElement() {
        if (!textareaElement) {
            container = document.createElement('div');
            container.id = 'simulate-clipboard-container';
            container.setAttribute('style', ['opacity: 0;height: 0;width: 0;'].join(''));
            document.body.appendChild(container);
            textareaElement = document.createElement('textarea');
            textareaElement.id = 'simulate-clipboard';
            container.appendChild(textareaElement);
        }
    }
    componentDidMount() {
        $("#" + this.state.uniqueId).hide();
        setTimeout(() => {
            $("#" + this.state.uniqueId).show();
            this.loadKendoMenu();
        }, 10);
    }

    loadKendoMenu = () => {
        $("#" + this.state.uniqueId).kendoContextMenu({
            target: this.props.attachTo,
            animation: {
                open: { effects: "slideIn:right" },
                duration: 500
            },
            orientation: "vertical",
            open: (e: any) => {
                console.log("open kendo menu");
                if ($(e.item).hasClass('mydisabled')) {
                    e.preventDefault();
                }
                let itemRole = $(e.item).attr('role');
                if (itemRole === "menubar") {
                    this.setState({
                        isOpen: true
                    });
                }
                this.props.onBeforeOpen(e);
            },
            select: (e:any) => {
                let data = {
                    wmsid : $(e.item).data('wmsid')
                }
                this.eventClickContextMenu(data);
            },
            close: (e: any) =>  {
                let itemRole = $(e.item).attr('role');
                if (itemRole === "menubar") {
                    this.setState({
                        isOpen: false
                    });
                }
            },
            filter: this.props.contextMenuFilter

        });

        $(this.props.attachTo).on("contextmenu", "tr>td",  (e: any) => {
            this.setState({ clipBoardText: e.target.innerText === '' ? ' ' : e.target.innerText });
            setTimeout(() => {

                var grid = $(this.props.attachTo).data("kendoGrid");
                if (!grid) {
                    return;
                }
                var selectedRow = grid.dataItem(grid.select());
                if (selectedRow) {
                    var filterData: any = [];
                    (selectedRow).forEach((value: any) => {
                        if (value !== null && value !== '' && typeof value !== 'object') {
                            filterData.push(value);
                        }
                    });
                    this.setState({ clipBoardTextRow: filterData.toString() });
                } else {
                    this.setState({ clipBoardTextRow: "Something wrong please try again" });
                }

            }, 0);

        });
    }

    onChangeFunc() { }

    eventClickContextMenu = (obj: any) => {
        if (obj.wmsid === 'copyField' || obj.wmsid === 'copyRow') {
            var copyText:any = obj.wmsid === 'copyField' ? this.state.clipBoardText : this.state.clipBoardTextRow;
            console.log("copyText",copyText);
            var copyTextarea:any = document.querySelector('#simulate-clipboard');
            document.createTextNode(copyText);
            copyTextarea.appendChild(document.createTextNode(copyText));
            copyTextarea.select();
            document.execCommand('copy');
            $('#simulate-clipboard').text('');
        }
        var contextMenu = $("#" + this.state.uniqueId).data("kendoContextMenu");
        // close the ContextMenu
        contextMenu.close(100, 100);
    }
    // close context menu on hover of button dropdown
    closeWmsContextMenu = () => {
        var contextMenu = $("#" + this.state.uniqueId).data("kendoContextMenu");
        // close the ContextMenu
        contextMenu.close(100, 100);
    }

    eventMouseEnter = (obj:any) => {
        this.props.eventMouseEnter(obj.disabledText);
    }

    eventMouseLeave = (obj:any) => {
        this.props.eventMouseLeave();
    }

    render() {
        return (
            <ul id={this.state.uniqueId} className="context-menu-wrapper"  >
                {this.props.menu.map((obj:any, index:any) =>
                    (typeof obj.isContextMenu === "undefined" || obj.isContextMenu === true) ?
                        obj.value.length <= 1 ?
                            <li
                                key={index}
                                data-wms-selector={obj.wmsSelector + '_C'}
                                className={obj.disabled === true ? "mydisabled k-item k-state-default" : 'k-item k-state-default'}
                                onMouseLeave={obj.disabled === true ? () => this.eventMouseLeave(obj) : () => function () { }}
                                onMouseEnter={obj.disabled === true ? () => this.eventMouseEnter(obj) : () => function () { }}
                                data-run-report={obj.runReport}
                                data-rdl-name={obj.rdlName}>
                                {obj.name }

                            </li> :
                            <li key={index} data-wms-selector={obj.wmsSelector + '_C'}>
                                 {obj.name }
                                <WmsContextMenuItem 
                                    items={obj.value} 
                                    count={index + 1} 
                                    onClickContextMenu={this.eventClickContextMenu} 
                                    eventMouseEnter={this.eventMouseEnter} 
                                    eventMouseLeave={this.eventMouseLeave} 
                                    onMouseEnter={ (e: any) => function () { }}
                                />
                            </li> : ""
                )}
            </ul>
        );
    }
};
WmsContextMenu.propTypes = {
    attachTo: PropTypes.string.isRequired,
    eventClickContextMenu: PropTypes.func.isRequired,
    menu: PropTypes.array.isRequired,
    contextMenuFilter: PropTypes.string,
    eventMouseEnter: PropTypes.func.isRequired,
    eventMouseLeave: PropTypes.func.isRequired,
    onBeforeOpen: PropTypes.func
}

WmsContextMenu.defaultProps = {
    contextMenuFilter: 'tbody tr:not(.k-grid-edit-row)'
}

export default WmsContextMenu;