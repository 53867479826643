import PropTypes from "prop-types";
import React, { MouseEvent, PureComponent } from "react";
import "./WmsButton.scss";

enum IType {
  button = "button",
  submit = "submit",
  reset = "reset",
}

interface IBaseProps {
  id?: string; // this should write the id to the button
  type?: string; // this should write the type to the button
  buttonType?: string; // ['green', 'yellow', 'red', 'black']
  wmsSelector?: string;  // this should set up a selector on the input
  label: string; // thi should set label on button
  icon?: string; // set icon in button
  disabled?: boolean; // disable button
  showToolTip?: boolean; // set tooltip show or not
  tooltipText?: string; // show text on hover as tooltip
  loading?: boolean; // this pass loading true/false base on process runing or complete
  parentClassName?: string; // parent div class name
  className?: string; // button class name
  onClickFunc?: (event: MouseEvent) => void;
  // this should control the onClick event and anything we need to determine before we send it up
}
interface IBaseState {
  isHovering?: boolean
}

type IProps = IBaseProps;
type IState = IBaseState;

class WmsButton extends PureComponent<IProps, IState> {
  public static propTypes = {};
  public static defaultProps = {};
  constructor(props: IProps) {
    super(props);
    this.state = {
      isHovering: false
    }
  }
  // Setting default props
  // static defaultProps = {
  //   type: "button" as IType,
  //   buttonType: "green",
  //   loading: false,
  //   showToolTip: false,
  //   disabled: false,
  //   tooltipText: "set tooltip text",
  //   icon: "",
  //   className: "",
  //   parentClassName: "",
  // }; 

  onClickFunc = (event: MouseEvent) => {
    if (this.props.onClickFunc) {
      this.props.onClickFunc(event);
    }
  }
  handleMouseHover = () => {
    this.setState({ isHovering: true });
  }

  handleMouseLeave = () => {
    this.setState({ isHovering: false });
  }

  render() {
    const {
      parentClassName, type, className, id, buttonType,
      disabled, wmsSelector, icon, loading, label, showToolTip
    } = this.props;
    return (
      <div className={`wms-button-component ${parentClassName}`}>
        <div className={"button_holder " + (this.props.loading ? "disabled_button" : "")}>
          <button
            type={type as IType}
            id={id}
            className={`${className} ${buttonType}`}
            onClick={this.onClickFunc}
            disabled={disabled || loading}
            data-wms-selector={wmsSelector}
          >
            {loading ? (<div className="btnloader"><i className=" fi-spin fi-xnsrhl-loading"></i></div>) : (icon && <i className={`${icon}`}></i>)}
            <div>{label}</div>
          </button>
          {
            disabled === true &&
            showToolTip === true &&
            loading === false &&
            (<div>
              <div className="div_disable" onMouseEnter={this.handleMouseHover}
                onMouseLeave={this.handleMouseLeave}>&nbsp;</div>
              {this.state.isHovering === true && (<div onMouseEnter={this.handleMouseHover} onMouseLeave={this.handleMouseLeave} className="wms-button-tooltip">{this.props.tooltipText}</div>)}
            </div>)
          }
        </div>
      </div>
    );
  }
}

WmsButton.propTypes = {
  id: PropTypes.string, // this should write the id to the button
  type: PropTypes.string, // this should write the type to the button
  buttonType: PropTypes.string, // ['green', 'yellow', 'red', 'black']
  // wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the input
  label: PropTypes.string.isRequired, // thi should set label on button
  icon: PropTypes.string, // set icon in button
  disabled: PropTypes.bool, // disable button
  showToolTip: PropTypes.bool, // set tooltip show or not
  tooltipText: PropTypes.string, // show text on hover as tooltip
  loading: PropTypes.bool, // this pass loading true/false base on process runing or complete
  onClickFunc: PropTypes.func,
  // this should control the onClick event and anything we need to determine before we send it up
};

WmsButton.defaultProps = {
  type: "button" as IType,
  buttonType: "green",
  loading: false,
  showToolTip: false,
  disabled: false,
  tooltipText: "set tooltip text",
  icon: "",
  className: "",
  parentClassName: "",
};

export default WmsButton;
