import React, { PureComponent } from "react";
import axios from "axios";
import WmsScrollTo from '../components/WmsScrollTo/WmsScrollTo';
import WmsCollapse from '../components/WmsCollapse/WmsCollapse';
import WmsInput from '../components/WmsInput/WmsInput';
import WmsModel from '../components/WmsModel/WmsModel';
import GroupGridComponent from '../components/GroupGridComponent/GroupGridComponent'
import * as GridConfig from './AmisComponent-configuration.virtual.jsx'
import "./Amis.scss";
import * as GlobalService from '../assets/js/global_services.js';

import WmsButton from '../components/WmsButton/WmsButton';

declare var window: any;
const { $ } = window;
interface IState {
  tearDownLoader?: boolean,
  bringUpLoader?: boolean,
  gridLoading?: boolean,
  deployAllLoading?: boolean,
  isOpenStatusViewModel?: boolean,
  viewStatusDetail?: any,
  statusGridOptions?: any,
  codeSame?: boolean,
  deployGridOptions?: any,
  statusGridColumn?: any,
  statusGridSchema?: any,
  deployGridColumn?: any,
  deployGridSchema?: any,
    hasDeployGridError?: boolean,
    variableBranchName?: string,
    updateVariableLoader?: boolean,
    isShowDeployTab?: boolean,
    password?: string,
}
interface IProps {
  navigateFrom?: string
}
class AmisComponent extends PureComponent<IProps, IState>  {

  constructor(props: IProps) {
    super(props);
    let columns = GridConfig.getAmisComponentGridDemoColumns(this.props.navigateFrom);
    let schema = GridConfig.getAmisComponentGridDemoSchema(this.props.navigateFrom);
    this.state = {
      tearDownLoader: false,
      bringUpLoader: false,
      gridLoading: false,
      deployAllLoading: false,
      isOpenStatusViewModel: false,
      viewStatusDetail: {},
      statusGridOptions: [],
      codeSame: true,
      deployGridOptions: [],
      variableBranchName: '',
      statusGridColumn: [{
        field: "name",
        title: "Name"
      }, {
        field: "status",
        title: "Status"
      }, {
        field: "code",
        title: "Code"
      }, {
        field: "state",
        title: "State"
      }],
      statusGridSchema: {
        model: {
          fields: {
            name: { type: 'string' },
            status: { type: 'string' },
            code: { type: 'string' },
            state: { type: 'string' }
          }
        }
      },
            deployGridColumn: columns.columns,
            deployGridSchema: schema,
            hasDeployGridError: false,
            isShowDeployTab: true,
            password: '',
        }
    }

  componentDidMount() {
    this.updateIcons();
    this.getStatusGrid(false)(null);
    window.jQuery("#stagingDeployGrid").on("click", "#build-deploy", (index: any, element: any) => {
      var currentDataItem = window.jQuery("#stagingDeployGrid").data("kendoGrid").dataItem(window.jQuery(index.target).closest('tr'));
      let deployElement: any = [];
      let environment = this.props.navigateFrom;
      if (this.props.navigateFrom === "production" || this.props.navigateFrom === 'productiondual') {
        let password = this.state.password;
        deployElement.push({
          'environment': environment,
          'ami': currentDataItem.ami,
          'role': currentDataItem.role,
          'password': password
        });
      } else if (this.props.navigateFrom === "sandbox") {
        deployElement.push({
          'environment': environment,
          'ami': currentDataItem.ami,
          'role': currentDataItem.role,
        });
      } else {
        deployElement.push({
          'environment': environment,
          'ami': currentDataItem.ami,
          'role': currentDataItem.role,
        });
      }
      axios.put('https://ky2mhvg52l.execute-api.us-east-2.amazonaws.com/main/deploy/ami', deployElement).then((response) => {
        GlobalService.showSuccessMsg('Deploy successfully');
      }).finally(() => {
        this.setState({ tearDownLoader: false });
        this.updateIcons();
      });
    });
    axios.get('https://2sj1lahtc4.execute-api.us-east-2.amazonaws.com/ami/variables?environment=' + this.props.navigateFrom).then((response) => {
      this.setState({
        variableBranchName: response.data
      });
    }).finally(() => {

    });
    }

    getStatusGrid = (loaderStart: any) => (e: any) => {

        let tmpIsShowDeployTab = true
        if (localStorage.getItem('loginRole') !== 'admin' && (this.props.navigateFrom === "production" || this.props.navigateFrom === 'productiondual')) {
            tmpIsShowDeployTab = false
        }
        this.setState({
            gridLoading: loaderStart,
            isShowDeployTab: tmpIsShowDeployTab
        });
        this.updateIcons();
        window.kendo.ui.progress(window.jQuery("#stagingStatusGrid"), true);
        axios.get('https://ky2mhvg52l.execute-api.us-east-2.amazonaws.com/main/deploy/status?environment=' + this.props.navigateFrom).then((statusResponse) => {
      var statusOption: any = [];
      var tmpCodeSame = statusResponse.data.codeSame;
      for (let i = 0; i < statusResponse.data.groups.length; i++) {
        const element = statusResponse.data.groups[i];
        for (let j = 0; j < element.instances.length; j++) {
          var instance = element.instances[j];
          instance['name'] = element.name;
          statusOption.push(instance)
        }
      }

      this.setState({
        statusGridOptions: statusOption,
        codeSame: tmpCodeSame
      });
    }).finally(() => {
      this.setState({
        gridLoading: false
      });
      this.updateIcons();
      window.kendo.ui.progress(window.jQuery("#stagingStatusGrid"), false);
    });;
  }
  getDeployGrid = (loaderStart: any) => (e: any) => {
    this.setState({ gridLoading: loaderStart });
    this.updateIcons();
    window.kendo.ui.progress(window.jQuery("#stagingDeployGrid"), true);
    var url = "https://ky2mhvg52l.execute-api.us-east-2.amazonaws.com/main/deploy/ami?environment=" + this.props.navigateFrom;
    //if (this.props.navigateFrom === 'sandbox') {
    //  url = 'https://ky2mhvg52l.execute-api.us-east-2.amazonaws.com/main/deploy/sync';
    //}
    axios.get(url).then((deployResponse) => {
      var deployOption: any = [];
      let tmpHasDeployGridError = false;
      Object.keys(deployResponse.data).forEach(function (k) {
        var arr = deployResponse.data[k];
        if (arr.length > 1) {
          for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
            deployOption.push(element)
          }
        } else {
          const element = deployResponse.data[k];
          if (element.Error !== '') {
            tmpHasDeployGridError = true;
          }
          deployOption.push(element);
        }
      });
	  
	  console.log(deployOption)
	  
      this.setState({
        deployGridOptions: deployOption,
        hasDeployGridError: tmpHasDeployGridError
      });
    }).finally(() => {
      this.setState({
        gridLoading: false
      });
      this.updateIcons();
      window.kendo.ui.progress(window.jQuery("#stagingDeployGrid"), false);
    });;
  }

  /* deployLatestAll = () => {
    var currentDataItem = window.jQuery("#stagingDeployGrid").data("kendoGrid").dataSource.data();
    let environment = this.props.navigateFrom;
    let deployElement: any = [];
    if (this.props.navigateFrom === "production") {
      let password = this.state.password;
      currentDataItem.forEach(function (element: any) {
        deployElement.push({
          'environment': environment,
          'ami': element.ami,
          'role': element.role,
          'password': password
        });
      });
    } else if (this.props.navigateFrom === "sandbox") {
      currentDataItem.forEach(function (element: any) {
        deployElement.push({
          'environment': environment,
          'ami': element.Ami,
          'role': element.Role,
        });
      });
    } else {
      currentDataItem.forEach(function (element: any) {
        deployElement.push({
          'environment': environment,
          'ami': element.ami,
          'role': element.role,
        });
      });
    }
    // console.log("deployLatestAll", { 'environment': this.props.navigateFrom, 'ami': "latest" })
    this.setState({ deployAllLoading: true });
    this.updateIcons();
    window.kendo.ui.progress(window.jQuery("#stagingDeployGrid"), true);
    axios.put('https://ky2mhvg52l.execute-api.us-east-2.amazonaws.com/main/deploy/ami', deployElement).then((deployResponse) => {
      GlobalService.showSuccessMsg('Latest Deploy Successfully');
    }).finally(() => {
      this.setState({
        deployAllLoading: false
      });
      this.updateIcons();
      this.getDeployGrid(false)(null);
    });
  } */

  updateIcons = () => {
    setTimeout(() => {
      if (window.friconix_update) {
        window.friconix_update();
      }
    }, 0);
  }

  onTearDownClick = () => {

    this.setState({ tearDownLoader: true });
    this.updateIcons();
    axios.post('https://6qut7c4p7c.execute-api.us-east-2.amazonaws.com/v1/' + this.props.navigateFrom, {
      'action': "down"
    }).then((response) => {
      GlobalService.showSuccessMsg('Tear down call successfully');
    }).finally(() => {
      this.setState({ tearDownLoader: false });
      this.updateIcons();
    });
  }
  onBringUpClick = () => {

    this.setState({ bringUpLoader: true });
    this.updateIcons();
    axios.post('https://6qut7c4p7c.execute-api.us-east-2.amazonaws.com/v1/' + this.props.navigateFrom, {
      'action': "up"
    }).then((response) => {
      GlobalService.showSuccessMsg('Bring up call successfully');
    }).finally(() => {
      this.setState({ bringUpLoader: false });
      this.updateIcons();
    });
  }

  onTabShow = () => {
    $("#stagingDeployGrid").data("kendoGrid").resize(true);
    if (this.state.deployGridOptions.length === 0) {
      this.getDeployGrid(false)(null);
    }
  }
  onGridChange = () => {

    let grid = $("#stagingStatusGrid").data("kendoGrid");
    let currentSelections = grid.select();
    console.log("currentSelections", currentSelections)
    if (currentSelections.length === 1) {
      $.map(grid.select(), (item: any) => {
        let selectItem = grid.dataItem(item);
        console.log("selectItem", selectItem);
        this.setState({
          isOpenStatusViewModel: true,
          viewStatusDetail: selectItem
        })
      });
    }

  }
  closeStatusViewModel = () => {
    this.setState({
      isOpenStatusViewModel: false,
      viewStatusDetail: {}
    })
  }
  handleChangeVariableBranchName = (e: any) => {
    this.setState({
      variableBranchName: e.target.value
    });
  }
  handleChangePassword = (e: any) => {
    this.setState({
      password: e.target.value
    });
  }
  onUpdateVariableBranch = (e: any) => {
    this.setState({ updateVariableLoader: true });
    this.updateIcons();
    console.log("onUpdateVariableBranch", { 'environment': this.props.navigateFrom, 'branch': this.state.variableBranchName });
    axios.put('https://2sj1lahtc4.execute-api.us-east-2.amazonaws.com/ami/variables', {
      'environment': this.props.navigateFrom,
      'branch': this.state.variableBranchName
    }).then((response) => {
      GlobalService.showSuccessMsg('Branch updated successfully');
    }).finally(() => {
      this.setState({ updateVariableLoader: false });
      this.updateIcons();
    });
  }
  render() {

    return (
      <div className="staging-section-wrapper staging-page">
        <div className="tab-listing" >
          <ul>
            <li>
              <WmsScrollTo
                label={'Status'}
                scrollToId='statusTabCollapse'
                scrollBoxId='ManageOrdersScrollBox'
                windowId="staging-page"
                                isTabSection={true}
                                tabCommonClassForHide={"staging-tab-content-wrapper"} />
                        </li>
                        {this.state.isShowDeployTab && (

                            <li>
                                <WmsScrollTo
                                    label={'Deploy'}
                scrollToId='deployTabCollapse'
                scrollBoxId='ManageOrdersScrollBox'
                windowId="staging-page"
                isTabSection={true}
                                    tabCommonClassForHide={"staging-tab-content-wrapper"}
                                    onTabShow={this.onTabShow} />
                            </li>
                        )}
                        <li>
                            <WmsScrollTo
                                label={'Infrastructure'}
                scrollToId='infrastructureTabCollapse'
                scrollBoxId='ManageOrdersScrollBox'
                windowId="staging-page"
                isTabSection={true}
                tabCommonClassForHide={"staging-tab-content-wrapper"} />
            </li>
          </ul>
        </div>
        <div id="ManageOrdersScrollBox" className="staging-page tab-content">
          <div className="status-wrapper staging-tab-content-wrapper" id="statusTabCollapse">
            <WmsCollapse
              id={'collapse1'}
              headerLabel="Status"
              showCollapseText={false}
              isExpanded={true}
              activeCollapse={'true'}
              windowId="staging-page"
              onCollapseClick={() => { }}>

              {this.state.codeSame === false && (
                <div className="code-same-notice">
                  Different Code Versions Detected
                </div>
              )}

              <GroupGridComponent
                wmsSelector={'stagingStatusGrid'}
                gridKeySelection={'name'}
                staticGridData={this.state.statusGridOptions}
                gridName={'stagingStatusGrid'}
                virtual={true}
                filterText={''}
                menu={[{
                  "name": '',
                  "wmsSelector": 'RefreshGrid',
                  "value": [],
                  "clickFunc": this.getStatusGrid(true),
                  "disabled": false,
                  "icon": "fi-xwsuxl-reload",
                  "color": "blue",
                  'isContextMenu': false,
                  'isButtonMenu': true,
                  'parentClassName': 'refresh-btn-wrapper',
                  'loading': this.state.gridLoading
                }, {
                  "name": "Copy",
                  "value": [{
                    "name": "Copy Field",
                    "value": [],
                    "disabled": false,
                    "disabledText": "Copy Field",
                    "icon": "fa-bandcamp",
                    "color": "green",
                    'isContextMenu': true,
                    'isButtonMenu': false,
                    'wmsid': 'copyField'
                  }, {
                    "name": "Copy Row",
                    "value": [],
                    "disabled": false,
                    "disabledText": "Copy Row",
                    "icon": "fa-wrench",
                    "color": "green",
                    'isContextMenu': true,
                    'isButtonMenu': false,
                    'wmsid': 'copyRow'
                  }],
                  "disabled": false,
                  "disabledText": "You may not add blahssss0",
                  "icon": "fa-wrench",
                  "color": "green",
                  'isContextMenu': true,
                  'isButtonMenu': false
                }]}
                allColumns={this.state.statusGridColumn}
                defaultColumns={this.state.statusGridColumn}
                showGridResult={true}
                showSortColumns={true}
                shawCheckbox={false}
                totalResult={this.state.statusGridOptions.length}
                id={'stagingStatusGrid'}
                groupKeyName='name'
                onSelection={() => { }}
                schema={this.state.statusGridSchema}
                clickEventOfContextMenu={() => { }}
                exportable={true}
                eventClickColumns={() => { }}
                eventClickExportGrid={() => { }}
                resetButtonLabel={'Label_Costomer_Options'}
                resetButtonIcon={'fa-sliders'}
                metaDataFieldsName={''}
                onGridChange={this.onGridChange} />
            </WmsCollapse >
          </div>
          <div className="deploy-wrapper staging-tab-content-wrapper" id="deployTabCollapse">
            <WmsCollapse
              id={'collapse2'}
              headerLabel="Deploy"
              showCollapseText={false}
              isExpanded={true}
              activeCollapse={'true'}
              windowId="staging-page"
              onCollapseClick={() => { }}>
              {this.props.navigateFrom !== 'sandbox' && this.props.navigateFrom !== 'production' && this.props.navigateFrom !== 'productiondual' && (
                <div className="variable-branch-wrapper">
                  <WmsInput
                    id={'variableBranchName'}
                    extraWrapperClass="variable-branch-name"
                    inputName={'variableBranchName'}
                    wmsSelector={'variableBranchName'}
                    label={''}
                    value={this.state.variableBranchName}
                    onChangeFunc={this.handleChangeVariableBranchName}
                  />
                  <WmsButton
                    label='Update'
                    wmsSelector={'Update'}
                    onClickFunc={this.onUpdateVariableBranch}
                    disabled={false}
                    loading={this.state.updateVariableLoader}
                    buttonType='blue'
                    icon={'fi-xwluxl-hard-floppy'}
                  />
                </div>
              )}

              {(this.props.navigateFrom === 'production' || this.props.navigateFrom === 'productiondual') && (
                <WmsInput
                  inputType="password"
                  id={'password'}
                  extraWrapperClass="production-password"
                  inputName={'password'}
                  placeholder="Password"
                  wmsSelector={'password'}
                  label={''}
                  value={this.state.password}
                  onChangeFunc={this.handleChangePassword}
                />
              )}

              <GroupGridComponent
                wmsSelector={'stagingDeployGrid'}
                gridKeySelection={'ami'}
                staticGridData={this.state.deployGridOptions}
                gridName={'stagingDeployGrid'}
                virtual={true}
                filterText={''}
                menu={[{
                  "name": '',
                  "wmsSelector": 'RefreshGrid',
                  "value": [],
                  "clickFunc": this.getDeployGrid(true),
                  "disabled": false,
                  "icon": "fi-xwsuxl-reload",
                  "color": "blue",
                  'isContextMenu': false,
                  'isButtonMenu': true,
                  'parentClassName': 'refresh-btn-wrapper',
                  'loading': this.state.gridLoading
                }, {
                  "name": "Copy",
                  "value": [{
                    "name": "Copy Field",
                    "value": [],
                    "disabled": false,
                    "disabledText": "Copy Field",
                    "icon": "fa-bandcamp",
                    "color": "green",
                    'isContextMenu': true,
                    'isButtonMenu': false,
                    'wmsid': 'copyField'
                  }, {
                    "name": "Copy Row",
                    "value": [],
                    "disabled": false,
                    "disabledText": "Copy Row",
                    "icon": "fa-wrench",
                    "color": "green",
                    'isContextMenu': true,
                    'isButtonMenu': false,
                    'wmsid': 'copyRow'
                  }],
                  "disabled": false,
                  "disabledText": "You may not add blahssss0",
                  "icon": "fa-wrench",
                  "color": "green",
                  'isContextMenu': true,
                  'isButtonMenu': false
                }]}
                allColumns={this.state.deployGridColumn}
                defaultColumns={this.state.deployGridColumn}
                showGridResult={true}
                showSortColumns={true}
                shawCheckbox={false}
                totalResult={this.state.deployGridOptions.length}
                id={'stagingDeployGrid'}
                groupKeyName={this.props.navigateFrom === "sandbox" ? 'role' : 'role'}
                onSelection={() => { }}
                schema={this.state.deployGridSchema}
                clickEventOfContextMenu={() => { }}
                exportable={true}
                eventClickColumns={() => { }}
                eventClickExportGrid={() => { }}
                resetButtonLabel={'Label_Costomer_Options'}
                resetButtonIcon={'fa-sliders'}
                metaDataFieldsName={''} />
            </WmsCollapse >
          </div>
          <div className="infrastructure-wrapper staging-tab-content-wrapper" id="infrastructureTabCollapse">
            <WmsCollapse
              id={'collapse3'}
              headerLabel="Infrastructure"
              showCollapseText={false}
              isExpanded={true}
              activeCollapse={'true'}
              windowId="staging-page"
              onCollapseClick={() => { }}>
              <div className="button-wrapper" >
                <WmsButton
                  id="tearDown"
                  type="submit"
                  label="Tear Down"
                  icon="fi-xwldxl-arrow-simple-wide"
                  onClickFunc={this.onTearDownClick}
                  loading={this.state.tearDownLoader}
                />
                <WmsButton
                  id="bringUp"
                  type="submit"
                  label="Bring Up"
                  icon="fi-xwluxl-arrow-simple-wide"
                  onClickFunc={this.onBringUpClick}
                  loading={this.state.bringUpLoader}
                />
              </div>
            </WmsCollapse >
          </div>
          {this.state.isOpenStatusViewModel && (
            <WmsModel
              id='viewStatusDetail'
              title={this.state.viewStatusDetail.name + "'s Detail"}
              width={540}
              height={415}
              customClass='wms-model-new-ui-wrapper'
              onCloseFunc={this.closeStatusViewModel}
              isOpen={this.state.isOpenStatusViewModel}
            >
              <div className="model-content-wrapper">
                <WmsCollapse
                  id={'viewBranchDetail'}
                  headerLabel={'Status Detail'}
                  showCollapseText={false}
                  showCollapseIcon={false}
                >
                  <div className='detail-section'>
                    <label>Name:</label>
                    <span>{this.state.viewStatusDetail.name}</span>
                  </div>
                  <div className='detail-section'>
                    <label>Status:</label>
                    <span>{this.state.viewStatusDetail.status}</span>
                  </div>
                  <div className='detail-section'>
                    <label>Code:</label>
                    <span>{this.state.viewStatusDetail.code}</span>
                  </div>
                  <div className='detail-section'>
                    <label>State:</label>
                    <span>{this.state.viewStatusDetail.state}</span>
                  </div>
                </WmsCollapse>
              </div>
            </WmsModel>

          )}
        </div>
      </div>
    );
  }
}


export default AmisComponent;
