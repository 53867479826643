import React, { PureComponent } from "react";
import axios from "axios";
import "./Dashboard.scss";
interface IState {
    dashboardMsg?: string;
}

class DashboardDefaultComponent extends PureComponent<{}, IState>  {

    constructor(props: any) {
        super(props);
        this.state = {
            dashboardMsg: ''
        };
    }

    componentDidMount() {
        axios.get('https://ky2mhvg52l.execute-api.us-east-2.amazonaws.com/main/dashboard').then((response) => {
            console.log("dashboard response", response)
            // response.data.role = 'user'
            window.localStorage.setItem("loginRole", response.data.role);
            this.setState({
                dashboardMsg: response.data.message + ' !'
            });
        }).finally(() => {

        });
    }



    render() {

        return (
            <div className="dashboard-default-section-wrapper">
                <div className="msg-wrapper" >
                    <span>{this.state.dashboardMsg}</span>
                </div>
            </div>
        );
    }
}


export default DashboardDefaultComponent;
