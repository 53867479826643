import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import  './WmsDropdown.scss';

interface IProps {
    id?: string, 
    label?: string, 
    value?: string, 
    name?: string, 
    required?: boolean, 
    wmsSelector?: string, 
    blankFirstOption?: boolean, 
    showToolTip?: boolean, 
    blankFirstOptionText?: string, 
    disabled?: boolean, 
    extraWrapperClass?: string, 
    parentClassName?: string, 
    textField?: any, 
    nestedValueField?: any, 
    tooltipText?: string, 
    valueField?: any, 
    applyIndex?: any, 
    options?: any, 
    onChangeFunc?: any, 
}

interface IState {
    value?: any,
    isHovering?: boolean,
}

class WmsDropdown extends PureComponent<IProps, IState> {
    public static propTypes = {};
    public static defaultProps = {};

    constructor(props: IProps) {
        super(props);
        this.state = {
            value: props.value 
        }
        // console.log('Parent',this.props.parentClassName);
    }
    onChangeFunc(e: any) {
        this.setState({
            value: e.target.value
        })
        this.props.onChangeFunc(e);
    }
    handleMouseHover = () => {
        this.setState({ isHovering: true });
    }

    handleMouseLeave = () => {
        this.setState({ isHovering: false });
    }
    render() {
        return (
            <div className={"wms-dropdown-component " + this.props.extraWrapperClass }>
                <label  htmlFor={this.props.id} >{this.props.label}</label>
                <div className={ (this.state.value !== null && this.state.value.toString()) || this.props.blankFirstOption === false || this.props.blankFirstOptionText !== "" ? `has_value ${this.props.parentClassName }` : this.props.parentClassName}>
                    <select
                        id={this.props.id}
                        name={this.props.name}
                        className={this.props.required && !this.props.value ? (this.props.disabled ? 'k_state_disabled ' : ' ') + "wms_required_input" : this.props.disabled ? 'k_state_disabled ' : ' '}
                        data-wms-selector={this.props.wmsSelector}
                        value={this.state.value != null ? this.state.value : ''}
                        onChange={this.onChangeFunc.bind(this)}
                        required={this.props.required}
                        disabled={this.props.disabled}
                    >
                        {this.props.blankFirstOption === true && (<option value="">{this.props.blankFirstOptionText}</option>)}
                        {this.props.applyIndex === true && (
                            this.props.options.map((Option:any , index: any) => (
                                (this.props.textField === '') ?
                                    <option key={index} value={index}>{Option}</option>
                                    :
                                    <option key={index} value={index}>{Option[this.props.textField] ? Option[this.props.textField] : ''}</option>

                            ))
                        )}
                        {this.props.applyIndex === false && (
                            this.props.options.map((Option:any , index:any) => (
                                <option key={index} value={this.props.nestedValueField === '' ? Option[this.props.valueField] : Option[this.props.valueField][this.props.nestedValueField]}>{Option[this.props.textField]}</option>
                            ))
                        )}
                    </select>
                    {
                        this.props.disabled === true &&
                        this.props.showToolTip === true &&
                        (<div>
                            <div className="div_disable" onMouseEnter={this.handleMouseHover}
                                onMouseLeave={this.handleMouseLeave}>&nbsp;</div>
                            {this.state.isHovering === true && <div className="div_tooltip">{this.props.tooltipText}</div>}
                        </div>)
                    }
                    <span className="caret"></span>
                </div>
            </div>
        );
    }
}

WmsDropdown.propTypes = {
    id: PropTypes.string, // this should write the id to the input,
    name: PropTypes.string, // this should write the id to the input,
    required: PropTypes.bool, // this determines if the input is required and shows in red
    disabled: PropTypes.bool,  // default will be false
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the input
    parentClassName: PropTypes.string, //    this should parent tag class default parent class is form-group
    extraWrapperClass: PropTypes.string, // extra wrapper class if needed
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ]), // this should control the value ,it`s string or number
    // options: PropTypes.array.isRequired, // dropdown option and it mus be require
    options: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]).isRequired,
    valueField: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),// dropdown option value and it mus be require
    textField: PropTypes.string,// dropdown option text and it mus be require
    label: PropTypes.string.isRequired,// dropdown title
    onChangeFunc: PropTypes.func.isRequired, // this should control the onchange event and anything we need to determine before we send it up
    applyIndex: PropTypes.bool,
    blankFirstOption: PropTypes.bool,
    setKendoDropdown: PropTypes.bool,
    blankFirstOptionText: PropTypes.string,
    showToolTip: PropTypes.bool, // set tooltip show or not
    tooltipText: PropTypes.string, // show text on hover as tooltip
    nextFocus: PropTypes.func
}
WmsDropdown.defaultProps = {
    required: false,
    disabled: false,
    blankFirstOption: false,
    blankFirstOptionText: "",
    parentClassName: "form_group",
    extraWrapperClass: '',
    tooltipText: 'add tooltip text',
    showToolTip: false,
    nestedValueField: '',
    applyIndex: false,
    setKendoDropdown: false,
    textField: '',
    windowId: '',
    nextFocus: (e:any) => () => { },
}
export default WmsDropdown;