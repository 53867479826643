import React, { PureComponent } from "react";
import NavigationComponent from "../Layouts/Navigation/NavigationComponent";
import ContentComponent from "../Layouts/Content/ContentComponent";
import "./Dashboard.scss";
interface IState {
  activeMenu?: string;
}

declare var window: any;
class DashboardComponent extends PureComponent<{}, IState>  {

  constructor(props: any) {
    super(props);
    this.state = {
      activeMenu: 'dashboard'
    };
  }

  componentDidMount() {
    if(window.friconix_update) {
      window.friconix_update();    
    }
  }

  onNavBarClick = (clickedNavItem: string) => {
    this.setState({activeMenu: clickedNavItem });
  }


  render() {

    return (
      <main>
        
        <NavigationComponent 
          onNavBarClick={this.onNavBarClick}
          activeMenu={this.state.activeMenu} />

        <ContentComponent 
          activeMenu={this.state.activeMenu}
        />
      </main>
    );
  }
}


export default DashboardComponent;
