
import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import './WmsContextMenuItem.scss';

interface IProps {
    items: any,
    count: number,
    eventMouseLeave: any,
    onMouseEnter: any,
    onClickContextMenu: any,
    eventMouseEnter: any
}

interface IState { }
class WmsContextMenuItem extends PureComponent<IProps, IState> {

    public static propTypes = {};
    public static defaultProps = {};

    onClickContextMenu = (item: any, e: any) => {
        console.log("onClickContextMenu item", item);
        this.props.onClickContextMenu(item, e)
    }
    handleCheck(e: any) {
        alert(e.target);
    }
    render() {
        return (
            <ul className="wms_context_sub_menu_item_wrapper">
                {this.props.items.map((item: any, index: any) =>
                    (typeof item.isContextMenu === "undefined" || item.isContextMenu === true) ?
                        <li
                            key={index}
                            data-wms-selector={item.wmsSelector + '_C'}
                            has-child-menu={item.value.length !== 0 ? 'true' : 'false'}
                            className={item.disabled === true ? "mydisabled k-item k-state-default" : 'k-item k-state-default'}
                            onMouseLeave={
                                (item.disabled === true) ?
                                    (() => this.props.eventMouseLeave()) :
                                    () => function () { }
                            }
                            onMouseEnter={
                                (item.disabled === true) ?
                                    (() => this.props.eventMouseEnter(item)) :
                                    (() => function () { })
                            }
                            data-wmsid={(item.wmsid) ? item.wmsid : ""}
                            onClick={item.disabled ? ((e) => function () { }) : ((e) => this.onClickContextMenu(item, e))}
                            data-run-report={item.runReport}
                            data-rdl-name={item.rdlName}
                        >
                            {item.name}
                            {item.isNewMenu === true && (
                                <span className="wms-badge">Beta</span>
                            )}
                            {item.value.length !== 0 && (
                                <WmsContextMenuItem
                                    items={item.value}
                                    count={index + 1}
                                    onClickContextMenu={this.onClickContextMenu}
                                    eventMouseEnter={this.props.eventMouseEnter}
                                    eventMouseLeave={this.props.eventMouseLeave}
                                    onMouseEnter={(e: any) => function () { }}
                                />
                            )}
                        </li> : ""
                )}
            </ul>
        )
    }
}

WmsContextMenuItem.propTypes = {
    items: PropTypes.array,
    count: PropTypes.number,
    eventMouseLeave: PropTypes.func,
    onMouseEnter: PropTypes.func
}

WmsContextMenuItem.defaultProps = {
    disabled: false,
    count: 0,
    eventMouseLeave: (e: any) => function () { },
    eventMouseEnter: (e: any) => function () { }
}

export default WmsContextMenuItem;